import React, { Fragment } from "react";
import { Caption, Detail, Grid, H5, HeaderLogo, Image } from "@maggioli-design-system/react";
import { Link } from "react-router-dom";
import LogoEditore from "@maggioli-design-system/identity/dist/maggioli-editore/logo-maggioli-editore.svg";
import Logo from "../../../assets/images/LOGO_Norme_PA.png";
import { TailSpin } from "react-loader-spinner";
import { IHandleLoader } from "../../../interfaces/IHandleLoader";
import { useLocation } from "react-router-dom";

const FooterWide = ({ year }) => (
    <Fragment>
        <Grid className="view-limit py-16 px-8 desktop:col-start-2 gap-12 bg-adjust-tone relative overflow-visible rounded-t-3xl w-full" columns="3">
            {/* Prima colonna del footer */}
            <div>
                <Grid gutter="xsmall">
                    <HeaderLogo src={Logo} className="w-36 h-auto -my-6" alt="logo" />
                    <Caption className="mt-4">
                        <strong>
                            Norme PA è un servizio del <br />
                            Gruppo Maggioli
                        </strong>
                    </Caption>
                    <Caption>
                        Via del Carpino, 8<br />
                        47822 Santarcangelo di Romagna (RN) Italia
                    </Caption>
                </Grid>
            </div>
            {/* Seconda colonna del footer */}
            <div>
                <Grid gutter="xsmall">
                    <H5>Servizio clienti</H5>
                    <Caption>
                        Disponibile dal Lunedì al Venerdì
                        <br />
                        Dalle 08:30 alle 17:30
                    </Caption>
                    <Caption>Tel. +39 0541 628200</Caption>
                    <Link to="/contatti">
                        <Detail className="text-brand-maggioli-03">
                            <p>Contattaci</p>
                        </Detail>
                    </Link>
                </Grid>
            </div>
            {/* Terza colonna del footer */}
            <div>
                <Grid gutter="xsmall">
                    <Detail>
                        <a href="/informativa-sui-cookies">Cookie policy</a>
                    </Detail>
                    <Detail>
                        <a href="https://privacy.maggiolicloud.it/privacy/sso.maggioli.it" target="_blank" rel="noreferrer">
                            Privacy policy
                        </a>
                    </Detail>
                    <Image className="w-16" src={LogoEditore} />
                </Grid>
            </div>
        </Grid>
        {/* Barra del footer */}
        <div className="bg-brand-maggioli-04 text-adjust-tone desktop:col-start-2">
            <div className="view-limit p-4 grid">
                <Caption className="m-auto w-full text-center">
                    Copyright 2003-{year} Maggioli Spa - P.Iva 02066400405 - Iscritta al R.E.A. di Rimini al n. 219107
                </Caption>
            </div>
        </div>
    </Fragment>
);

const FooterCompact = ({ year }) => (
    <Fragment>
        <div className="tablet:gap-x-12 grid large:max-w-screen-wide desktop-max:w-full mobile:pt-0 gap-0 mx-auto px-6 relative template-testo-single">
            <Grid
                className="view-limit py-16 px-8 desktop:col-start-2 gap-12 bg-adjust-tone relative overflow-visible rounded-t-3xl w-full"
                columns="3"
            >
                {/* Prima colonna del footer */}
                <div>
                    <Grid gutter="xsmall">
                        <HeaderLogo src={Logo} className="w-36 h-auto -my-6" alt="logo" />
                        <Caption className="mt-4">
                            <strong>
                                Norme PA è un servizio del <br />
                                Gruppo Maggioli
                            </strong>
                        </Caption>
                        <Caption>
                            Via del Carpino, 8<br />
                            47822 Santarcangelo di Romagna (RN) Italia
                        </Caption>
                    </Grid>
                </div>
                {/* Seconda colonna del footer */}
                <div>
                    <Grid gutter="xsmall">
                        <H5>Servizio clienti</H5>
                        <Caption>
                            Disponibile dal Lunedì al Venerdì
                            <br />
                            Dalle 08:30 alle 17:30
                        </Caption>
                        <Caption>Tel. +39 0541 628200</Caption>
                        <Link to="/contatti">
                            <Detail className="text-brand-maggioli-03">
                                <p>Contattaci</p>
                            </Detail>
                        </Link>
                    </Grid>
                </div>
                {/* Terza colonna del footer */}
                <div>
                    <Grid gutter="xsmall">
                        <Detail>
                            <a href="/informativa-sui-cookies">Cookie policy</a>
                        </Detail>
                        <Detail>
                            <a href="https://privacy.maggiolicloud.it/privacy/sso.maggioli.it" target="_blank" rel="noreferrer">
                                Privacy policy
                            </a>
                        </Detail>
                        <Image className="w-16" src={LogoEditore} />
                    </Grid>
                </div>
            </Grid>
        </div>
        {/* Barra del footer */}
        <div className="tablet:gap-x-12 grid large:max-w-screen-wide desktop-max:w-full mobile:pt-0 gap-0 mx-auto px-6 relative template-testo-single">
            <div className="bg-brand-maggioli-04 text-adjust-tone desktop:col-start-2">
                <div className="view-limit p-4 grid">
                    <Caption className="m-auto w-full text-center">
                        Copyright 2003-{year} Maggioli Spa - P.Iva 02066400405 - Iscritta al R.E.A. di Rimini al n. 219107
                    </Caption>
                </div>
            </div>
        </div>
    </Fragment>
);

interface Props {
    handleLoader: IHandleLoader;
    wide?: Boolean;
}

const Footer = ({ handleLoader }: Props) => {
    const CURRENT_YEAR: number = new Date().getFullYear();
    const location = useLocation();

    const [wide, setWide] = React.useState<boolean>(false);

    React.useEffect(() => {
        setWide(!location.pathname.includes("/testo"));
    }, [location]);

    const loaderComponent = () => (
        <>
            <div className="bg-loader-norme" />
            <div className="loader-norme">
                <TailSpin type={"TailSpin"} color="#0041B9" timeout={60000} />
            </div>
        </>
    );

    return (
        <footer>
            {handleLoader.showLoader && loaderComponent()}

            {wide ? <FooterWide year={CURRENT_YEAR} /> : <FooterCompact year={CURRENT_YEAR} />}
        </footer>
    );
};

export default Footer;
