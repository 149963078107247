import React from "react";

export default function TextDlgs() {
    return (
        <div>
            <b>
                (D.Lgs. n. 201/2022) <br />
                <br />
            </b>
            Il Decreto Legislativo n. 201/2022 è la normativa fondamentale in materia di{" "}
            <strong>Servizi Pubblici locali a rilevanza economica</strong> e rappresenta una vera novità in una materia da sempre caratterizzata da
            disorganicità normativa, che qualunque amministrazione pubblica o soggetto privato, che si affacci al mondo dei servizi pubblici, deve
            avere sottomano. Essendo un testo normativo particolarmente recente, la prassi amministrativa è ancora incerta ed in via di
            consolidamento. Pertanto, sono la giurisprudenza amministrativa e contabile a svolgere un ruolo ermeneutico centrale nel delineare i
            contorni applicativi delle singole norme.
            <br />
            <br />
            La versione telematica, costantemente aggiornata, permette di consultare ciascuna norma verificando contemporaneamente la giurisprudenza
            di riferimento più attuale, nonché le delibere della Corte dei Conti su ciascun aspetto di questa normativa, così che gli addetti ai
            lavori possano essere sempre aggiornati sugli sviluppi degli orientamenti giurisprudenziali e sulle migliori prassi da seguire nel
            difficile compito di affidare e gestire i servizi pubblici locali a rilevanza economica.
            <br />
            <br />
            L’obiettivo è quello di consentire agli addetti del settore di poter contare su uno strumento pratico e allo stesso tempo facilmente
            accessibile, che consenta di capire velocemente come approcciare ogni caso concreto e come applicare le singole norme.
        </div>
    );
}
