import React, { ReactElement, useEffect } from "react";
import { H2, H3, H4 } from "@maggioli-design-system/react";

export default function Cookies(): ReactElement {
    useEffect(() => {
        loadPolicy();
    }, []);

    const loadPolicy = () => {
        const d = window.document;
        var s = d.createElement("script"),
            tag = d.getElementsByTagName("script")[0];
        s.src = "https://cdn.iubenda.com/iubenda.js";
        tag.parentNode.insertBefore(s, tag);
    };

    return (
        <div className="view-limit padding-normal background-color-adjust-tone-19 my-20">
            <div className="col-md-12 single-content">
                <H2>Cookie policy di normepa.it</H2>
                <p>
                    Questo documento contiene informazioni in merito alle tecnologie che consentono a questo Sito Web di raggiungere gli scopi
                    descritti di seguito. Tali tecnologie permettono al Titolare di raccogliere e salvare informazioni (per esempio tramite l’utilizzo
                    di Cookie) o di utilizzare risorse (per esempio eseguendo uno script) sul dispositivo dell’Utente quando quest’ultimo interagisce
                    con questo Sito Web.
                </p>
                <p>
                    Per semplicità, in questo documento tali tecnologie sono sinteticamente definite “Strumenti di Tracciamento”, salvo vi sia ragione
                    di differenziare.
                    <br />
                    Per esempio, sebbene i Cookie possano essere usati in browser sia web sia mobili, sarebbe fuori luogo parlare di Cookie nel
                    contesto di applicazioni per dispositivi mobili, dal momento che si tratta di Strumenti di Tracciamento che richiedono la presenza
                    di un browser. Per questo motivo, all’interno di questo documento il termine Cookie è utilizzato solo per indicare in modo
                    specifico quel particolare tipo di Strumento di Tracciamento.
                </p>

                <p>
                    Alcune delle finalità per le quali vengono impiegati Strumenti di Tracciamento potrebbero, inoltre richiedere il consenso
                    dell’Utente. Se viene prestato il consenso, esso può essere revocato liberamente in qualsiasi momento seguendo le istruzioni
                    contenute in questo documento.
                </p>

                <p>
                    Questo Sito Web utilizza Strumenti di Tracciamento gestiti direttamente dal Titolare (comunemente detti Strumenti di Tracciamento
                    “di prima parte”) e Strumenti di Tracciamento che abilitano servizi forniti da terzi (comunemente detti Strumenti di Tracciamento
                    “di terza parte”). Se non diversamente specificato all’interno di questo documento, tali terzi hanno accesso ai rispettivi
                    Strumenti di Tracciamento.
                    <br />
                    Durata e scadenza dei Cookie e degli altri Strumenti di Tracciamento simili possono variare a seconda di quanto impostato dal
                    Titolare o da ciascun fornitore terzo. Alcuni di essi scadono al termine della sessione di navigazione dell’Utente.
                    <br />
                    In aggiunta a quanto specificato nella descrizione di ciascuna delle categorie di seguito riportate, gli Utenti possono ottenere
                    informazioni più dettagliate ed aggiornate sulla durata, così come qualsiasi altra informazione rilevante - quale la presenza di
                    altri Strumenti di Tracciamento - nelle privacy policy dei rispettivi fornitori terzi (tramite i link messi a disposizione) o
                    contattando il Titolare.
                </p>

                <H3 className="mt-7">Attività strettamente necessarie a garantire il funzionamento di questo Sito Web e la fornitura del Servizio</H3>
                <a
                    href="https://www.iubenda.com/privacy-policy/94303825/cookie-policy/section/technical-cookies"
                    className="iubenda-white no-brand iubenda-noiframe iubenda-embed iub-legal-only iubenda-noiframe iub-body-embed"
                    title="Cookie Policy"
                >
                    Cookie Policy
                </a>

                <a
                    href="https://www.iubenda.com/privacy-policy/94303825/cookie-policy/section/other-types-cookies"
                    className="iubenda-white no-brand iubenda-noiframe iubenda-embed iub-legal-only iubenda-noiframe iub-body-embed"
                    title="Cookie Policy"
                >
                    Cookie Policy
                </a>

                <H3 className="iub-subheading iub-subheading-manage-gdpr mt-7" id="manage_cookies">
                    Come gestire le preferenze e prestare o revocare il consenso
                </H3>
                <p>
                    Esistono vari modi per gestire le preferenze relative agli Strumenti di Tracciamento e per prestare o revocare il consenso, ove
                    necessario. In qualsiasi pagina del Sito, cliccando sul bottone "Preference Privacy" in basso a sinistra si aprirà il banner dei
                    cookie e sarà possibile modificare e reimpostare le proprie preferenze; selezionando “accetta tutti i cookie” si presta il
                    consenso all’uso di tutti i tipi di cookie, mentre si può revocare il consenso cliccando su “rifiuta tutti i cookie”. Decidendo di
                    chiudere il banner, le preferenze impostate in precedenza verranno conservate sino a successiva modifica. Aprendo il banner si
                    possono prestare consensi differenziati per i vari tipi di cookie sulla base delle proprie preferenze.
                </p>
                <p>
                    Gli Utenti possono gestire le preferenze relative agli Strumenti di Tracciamento direttamente tramite le impostazioni dei propri
                    dispositivi - per esempio, possono impedire l’uso o l’archiviazione di Strumenti di Tracciamento.
                </p>
                <p>
                    In aggiunta, ogni qualvolta l’utilizzo di Strumenti di Tracciamento dipenda da consenso, l’Utente può prestare o revocare tale
                    consenso impostando le proprie preferenze all’interno dell’informativa sui cookie o aggiornando tali preferenze tramite il widget
                    delle impostazioni di tracciamento, se presente.
                </p>
                <p>
                    Grazie ad apposite funzioni del browser o del dispositivo è anche possibile rimuovere Strumenti di Tracciamento precedentemente
                    salvati.
                </p>
                <p>
                    Altri Strumenti di Tracciamento presenti nella memoria locale del browser possono essere rimossi cancellando la cronologia di
                    navigazione.
                </p>

                <p>
                    Per quanto riguarda Strumenti di Tracciamento di terza parte, gli Utenti possono gestire le preferenze e revocare il consenso
                    visitando il relativo link di opt out (qualora disponibile), utilizzando gli strumenti descritti nella privacy policy della terza
                    parte o contattandola direttamente.
                </p>

                <H4 className="mt-7">Individuare le impostazioni relative agli Strumenti di Tracciamento</H4>
                <p>
                    Gli Utenti possono, per esempio, trovare informazioni su come gestire i Cookie in alcuni dei browser più diffusi ai seguenti
                    indirizzi:
                </p>
                <ul>
                    <li>
                        <a
                            rel="noopener nofollow noreferrer"
                            target="_blank"
                            href="https://support.google.com/chrome/answer/95647?hl=it&p=cpn_cookies"
                        >
                            Google Chrome
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noopener nofollow noreferrer"
                            target="_blank"
                            href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie"
                        >
                            Mozilla Firefox
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noopener nofollow noreferrer"
                            target="_blank"
                            href="https://support.apple.com/it-it/guide/safari/manage-cookies-and-website-data-sfri11471/"
                        >
                            Apple Safari
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noopener nofollow noreferrer"
                            target="_blank"
                            href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies"
                        >
                            Microsoft Internet Explorer
                        </a>
                    </li>
                    <li>
                        <a rel="noopener nofollow noreferrer" target="_blank" href="https://support.microsoft.com/it-it/help/4027947">
                            Microsoft Edge
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noopener nofollow noreferrer"
                            target="_blank"
                            href="https://support.brave.com/hc/articles/360022806212-How-do-I-use-Shields-while-browsing"
                        >
                            Brave
                        </a>
                    </li>
                    <li>
                        <a rel="noopener nofollow noreferrer" target="_blank" href="https://help.opera.com/latest/web-preferences/#cookies">
                            Opera
                        </a>
                    </li>
                </ul>
                <p>
                    Gli Utenti possono inoltre gestire alcuni Strumenti di Tracciamento per applicazioni mobili disattivandoli tramite le apposite
                    impostazioni del dispositivo, quali le impostazioni di pubblicità per dispositivi mobili o le impostazioni relative al
                    tracciamento in generale (gli Utenti possono consultare le impostazioni del dispositivo per individuare quella pertinente).
                </p>

                <H4 className="mt-7">Come disattivare la pubblicità basata sugli interessi</H4>
                <p>
                    Fermo restando quanto precede, si informano gli Utenti della possibilità di avvalersi delle informazioni presenti su
                    <a target="_blank" rel="noopener nofollow noreferrer" href="http://www.youronlinechoices.eu/">
                        YourOnlineChoices
                    </a>{" "}
                    (EU),
                    <a target="_blank" rel="noopener nofollow noreferrer" href="https://thenai.org/about-online-advertising/">
                        Network Advertising Initiative
                    </a>{" "}
                    (USA) e
                    <a target="_blank" rel="noopener nofollow noreferrer" href="https://www.aboutads.info/consumers/">
                        Digital Advertising Alliance
                    </a>{" "}
                    (USA),
                    <a target="_blank" rel="noopener nofollow noreferrer" href="https://youradchoices.ca/understanding-online-advertising/">
                        DAAC
                    </a>{" "}
                    (Canada),
                    <a target="_blank" rel="noopener nofollow noreferrer" href="http://www.ddai.info/optout">
                        DDAI
                    </a>{" "}
                    (Giappone) o altri servizi analoghi. Con questi servizi è possibile gestire le preferenze di tracciamento della maggior parte
                    degli strumenti pubblicitari. Il Titolare, pertanto, consiglia agli Utenti di utilizzare tali risorse in aggiunta alle
                    informazioni fornite nel presente documento.
                </p>
                <p>
                    La Digital Advertising Alliance mette inoltre a disposizione un’applicazione chiamata
                    <a target="_blank" rel="noopener nofollow noreferrer" href="https://youradchoices.com/appchoices">
                        AppChoices
                    </a>{" "}
                    che aiuta gli Utenti a controllare la pubblicità comportamentale sulle applicazioni mobili.
                </p>

                <H4 className="mt-7">Conseguenze del rifiuto del consenso</H4>
                <p>
                    Gli Utenti sono liberi di decidere se prestare o meno il consenso. Tuttavia, si noti che gli Strumenti di Tracciamento consentono
                    a questo Sito Web di fornire una migliore esperienza e funzionalità avanzate agli Utenti (in linea con le finalità delineate nel
                    presente documento). Pertanto, in assenza del consenso dell'Utente, il Titolare potrebbe non essere in grado di fornire le
                    relative funzionalità.
                </p>

                <H3 className="iub-subheading iub-subheading-owner-and-data-controller mt-7" id="owner_of_the_data">
                    Titolare del Trattamento dei Dati
                </H3>
                <p>
                    Il Titolare del trattamento dei dati è Maggioli S.p.A., con sede in Via del Carpino n. 8, Santarcangelo di Romagna, (RN) CAP
                    47822.
                    <br />
                    <br />
                    Il Titolare ha provveduto a nominare il Responsabile della protezione dei dati che potrà essere contattato ai seguenti indirizzi:{" "}
                    <br />
                    mail dpo.privacy@maggioli.it <br />
                    pec dpo.privacy@maggioli.legalmail.it.
                    <br />
                    <br />
                    <strong>Esercizio dei diritti</strong> <br />
                    Per qualsiasi ulteriore informazione o esercitare i diritti di cui agli artt. 15-22 del Regolamento UE 2016/679 potrà inviare una
                    e-mail a privacy@maggioli.it. Infine, Lei ha il diritto di proporre un reclamo al Garante per la protezione dei dati personali.
                </p>
                <p>
                    <strong>Indirizzo email del Titolare:</strong> privacy@maggioli.it
                </p>

                <p>
                    Dal momento che l’uso di Strumenti di Tracciamento di terza parte su questo Sito Web non può essere completamente controllato dal
                    Titolare, ogni riferimento specifico a Strumenti di Tracciamento di terza parte è da considerarsi indicativo. Per ottenere
                    informazioni complete, gli Utenti sono gentilmente invitati a consultare la privacy policy dei rispettivi servizi terzi elencati
                    in questo documento.
                </p>
                <p>
                    Data l'oggettiva complessità di identificazione delle tecnologie di tracciamento, gli Utenti sono invitati a contattare il
                    Titolare qualora volessero ricevere ulteriori informazioni in merito all'utilizzo di tali tecnologie su questo Sito Web.
                </p>

                <H3 className="expand-click w_icon_24 icon_ribbon mt-7">Definizioni e riferimenti legali</H3>
                <H4>Dati Personali (o Dati)</H4>
                <p>
                    Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra
                    informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.
                </p>

                <H4 className="mt-7">Dati di Utilizzo</H4>
                <p>
                    Sono le informazioni raccolte automaticamente attraverso questo Sito Web (anche da applicazioni di parti terze integrate in questo
                    Sito Web), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall’Utente che si connette con questo Sito Web,
                    gli indirizzi in notazione URI (Uniform Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la
                    richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server
                    (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal
                    visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli
                    relativi all&rsquo;itinerario seguito all&rsquo;interno dell&rsquo;Applicazione, con particolare riferimento alla sequenza delle
                    pagine consultate, ai parametri relativi al sistema operativo e all&rsquo;ambiente informatico dell&rsquo;Utente.
                </p>

                <H4 className="mt-7">Utente</H4>
                <p>L'individuo che utilizza questo Sito Web che, salvo ove diversamente specificato, coincide con l'Interessato.</p>

                <H4 className="mt-7">Interessato</H4>
                <p>La persona fisica cui si riferiscono i Dati Personali.</p>

                <H4 className="mt-7">Responsabile del Trattamento (o Responsabile)</H4>
                <p>
                    La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare,
                    secondo quanto esposto nella presente privacy policy.
                </p>

                <H4 className="mt-7">Titolare del Trattamento (o Titolare)</H4>
                <p>
                    La persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina
                    le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al
                    funzionamento ed alla fruizione di questo Sito Web. Il Titolare del Trattamento, salvo quanto diversamente specificato, è il
                    titolare di questo Sito Web.
                </p>

                <H4 className="mt-7">Questo Sito Web (o questa Applicazione)</H4>
                <p>Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.</p>

                <H4 className="mt-7">Servizio</H4>
                <p>Il Servizio fornito da questo Sito Web così come definito nei relativi termini (se presenti) su questo sito/applicazione.</p>

                <H4 className="mt-7">Unione Europea (o UE)</H4>
                <p>
                    Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo documento si intende esteso a tutti
                    gli attuali stati membri dell’Unione Europea e dello Spazio Economico Europeo.
                </p>

                <H4 className="mt-7">Cookie</H4>
                <p>
                    I Cookie sono Strumenti di Tracciamento che consistono in piccole porzioni di dati conservate all'interno del browser dell'Utente.
                </p>

                <H4 className="mt-7">Strumento di Tracciamento</H4>
                <p>
                    Per Strumento di Tracciamento s’intende qualsiasi tecnologia - es. Cookie, identificativi univoci, web beacons, script integrati,
                    e-tag e fingerprinting - che consenta di tracciare gli Utenti, per esempio raccogliendo o salvando informazioni sul dispositivo
                    dell’Utente.
                </p>

                <hr />

                <H4 className="mt-7">Riferimenti legali</H4>
                <p>
                    La presente informativa sui cookie è resa ai sensi del Regolamento (UE) 2016/679 (di seguito “Regolamento”) e nel rispetto
                    dell’articolo 122 del D. Lgs. n. 196/2003 – Codice in materia di protezione dei dati personali (di seguito “Codice Privacy”),
                    nonché dei Provvedimenti generali del Garante per la Protezione dei Dati Personali n. 229 dell’8 maggio 2014 e n. 231 del 10
                    giugno 2021 in materia di cookie.
                </p>
                <p>Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questo Sito Web.</p>
            </div>
        </div>
    );
}
