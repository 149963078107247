import React from 'react'
import {
    Grid,
    H5,
    H3,
    Caption,
} from '@maggioli-design-system/react'
import ContactForm from '../../components/ContactForm/ContactForm'
import { IHandleLoader } from '../../interfaces/IHandleLoader'

interface Props {
    handleLoader: IHandleLoader,
}

const Contact = ({ handleLoader }: Props) => {
    return (
        <div className="padding-normal background-color-adjust-tone-19">

            {/* Titolo */}
            <H3 className="text-center vertical-padding-xxsmall my-6">Contatti</H3>

            <Grid className="view-limit lg:px-8 mobile:px-4" columns="2" gutter="xlarge" rows="fit-vertically">

                <Grid>
                    <H5>Richiesta informazioni</H5>
                    
                    {/* Form contatti */}
                    <ContactForm loader={handleLoader}/> 
                </Grid>
                <div className="tablet:pt-16 mobile:-pt-5 border-adjust-tone-15 lg:border-none">

                    {/* Info di contatto */}
                    <Grid gutter="xsmall">
                        <H5>Servizio clienti</H5>
                        <Caption>
                            Disponibile dal Lunedì al Venerdì<br />
                            Dalle 08:30 alle 17:30
                        </Caption>
                        <Caption>Tel. +39 0541 628200</Caption>
                    </Grid>
					<Grid gutter="xsmall" className="py-16">
						<H5>Maggioli Editore</H5>
						<Caption>
                            Via del Carpino, 8<br />47822 Santarcangelo di Romagna (RN) Italia
                        </Caption>
					</Grid>
				</div>
            </Grid>
        </div>
    )
}

export default Contact
