import React from 'react'


export default function TextCac() {
    return (
        <div>
            <b>
                Codicedeicontrattipubblici.it <br /><br />
            </b>
            <b>
                Autori: Ornella Cutajar e Alesssandro Massari <br />
                (con la collaborazione di Carla Ragionieri) <br /><br />
            </b>

            <i>Codicedeicontrattipubblici.it</i> si conferma un punto di riferimento concreto per gli operatori del settore dei contratti pubblici
            con un aggiornamento quotidiano della giurisprudenza interna ed europea, dei provvedimenti ANAC e  dei Pareri MIT che si occupano
            delle singole questioni.
            <br /><br />

            Un aggiornamento che riteniamo importante anche per il Commentario cartaceo che da anni pubblichiamo nell’ottica
            di fornire un supporto costante squisitamente operativo.
            <br /><br />

            Con la pubblicazione del <b>D,lgs. 31 marzo 2023, n. 36</b>, abbiamo prestato subito particolare attenzione nel seguirne gli sviluppi,
            dall’entrata in vigore (dal 1 aprile 2023), all’efficacia (dal 1 luglio 2023), nonché alle questioni legate al periodo transitorio
            fino al 31 dicembre 2023 e al periodo successivo su cui lavoriamo con attenzione quotidiana.
            <br /><br />

            La nostra verifica consueta dei principi di diritto rinvenibili nella giurisprudenza e nella prassi continua così con riferimento 
            agli articoli del d.lgs. 163 del 2006 e del D.lgs. 50 del 2016, cui manteniamo le specifiche sezioni e si arricchisce via via con 
            le decisioni a commento del D. Lgs. 36 del 2023, tenendo presenti le novità apportate dal nuovo Codice. <br /><br />

            Tra queste, solo per citarne alcune:
            <ul class="list-disc list-inside">
                <li> i <b>principi</b> (come il principio del risultato di cui all’articolo 1 e il principio di fiducia nell’azione amministrativa di cui all’articolo 2)</li>
                <li> la <b>digitalizzazione</b> ovvero un “ecosistema di approvvigionamento digitale” (come lo definisce l’<b>articolo 22</b>), che si realizza con la Banca dati nazionale dei contratti pubblici (<b>articolo 23</b>), il Fascicolo Virtuale dell’Operatore Economico (<b>articolo 24</b>) e la completa digitalizzazione della procedura di accesso agli atti (articolo 36)</li>
                <li> il <b>RUP</b> come Responsabile Unico del Progetto (<b>articolo 15</b>) e la possibile nomina di Responsabili di procedimento per le singole fasi di programmazione, progettazione, affidamento ed esecuzione</li>
                <li> la <b>progettazione</b> e, quindi, la riduzione a due livelli di progettazione: progetto di fattibilità tecnico-economica e progetto esecutivo (<b>articolo 41</b>)</li>
                <li><b>BIM obbligatorio dal 1 gennaio 2025 (articolo 43)</b></li>
                <li>l’<b>appalto integrat</b>o, con la possibilità di affidare congiuntamente la progettazione esecutiva e l’esecuzione dei lavori sulla base di un progetto di fattibilità tecnico economica, fatta eccezione per gli appalti di opere di manutenzione ordinaria (<b>articolo 44</b>);</li>
                <li>i <b>contratti sotto-soglia</b> con la ricezione delle modalità di affidamento dei contratti sotto soglia che erano state introdotte dal Decreto Semplificazioni DL 76 del 2020 per accelerare e semplificare le procedure (articolo 50)</li>
                <li>il <b>principio di rotazione (articolo 49)</b></li>
                <li>le <b>garanzie per i contratti sotto-soglia (articolo 53)</b></li>
                <li>il <b>subappalto</b> e le novità sul subappalto “a cascata” (<b>articolo 119</b>)</li>
                <li>la <b>revisione prezzi (articolo 60)</b> e la <b>rinegoziazione</b> dei contratti (<b>articolo 9</b>)</li>
                <li>i <b>gravi illeciti professionali (articolo 98)</b></li>
                <li>il <b>sistema di qualificazione</b> degli operatori economici anche per gli appalti di servizi e forniture (<b>articolo 100</b>)</li>
                <li>il <b>sistema di qualificazione delle stazioni appaltanti (articolo 63)</b></li>
                <li>la “liberalizzazione” del <b>criterio dell’offerta economicamente più vantaggiosa (articolo 108</b>; superamento del limite dei 30 punti al prezzo)</li>
                <li>la nuova disciplina delle <b>offerte anomale</b> sotto e sopra soglia (<b>articoli 54 e 110</b>)</li>
                <li>negli appalti di lavori la facoltà per l’appaltatore di richiedere, prima della conclusione del contratto, la sostituzione della garanzia definitiva con ritenute a valere sugli stati di avanzamento (<b>articolo 117</b>)</li>
                <li>la <b>modifica ai contratti in corso di esecuzione (articolo 120)</b></li>
            </ul> <br /><br />

            Particolare attenzione viene data anche ai risvolti di natura processuale che deriveranno dall’aggiornamento degli articoli <b>120, 121</b> e <b>124</b> del
            Codice del processo amministrativo (<b>D.lgs 2 luglio 2010, n. 104</b>) e che potremo verificare nel contenzioso che se ne occuperà di volta in volta
            nella specifica sezione che viene aggiunta per le importanti verifiche della normativa processuale speciale del rito appalti..
            <br/><br />

            Codicedeicontrattipubblici.it, per la sua completezza, mantiene sempre il raccordo con il Commentario cartaceo, di cui costituisce costante aggiornamento e dove sarà sempre nostra cura esporre tutte le novità apportate dalla normativa, 
            dalla giurisprudenza e dalla dottrina che si esprime a commento dei singoli articoli.
            <br/><br />

            Per tutti questi profili si conferma sostanziale il contributo di ricerca giurisprudenziale quotidiana e di selezione bibliografica di Carla Ragionieri, che cura altresì tutta la selezione dei Pareri MIT e dei provvedimenti ANAC.
            <br/><br />

            Le opinioni e i consigli dei lettori ovviamente saranno sempre molto graditi nel nostro  percorso.
            <br/><br />

            Santarcangelo di Romagna, settembre 2024
            <br/><br />

            Ornella Cutajar e Alessandro Massari 


        </div>
    )
}