import React from "react";

export default function TextTusp() {
    return (
        <div>
            <b>
                (D.Lgs. n. 175/2016) <br />
                <br />
            </b>
            Il Decreto Legislativo n. 175/2016, anche detto <strong>Testo Unico in materia di Società a Partecipazione pubblica</strong>, è la
            normativa fondamentale e di riferimento per la costituzione delle società da parte della pubblica amministrazione, nonché per l’acquisto,
            gestione e mantenimento da parte della P.A. delle partecipazioni pubbliche all’interno delle società.
            <br />
            <br />
            La versione telematica, costantemente aggiornata, permette di consultare ciascuna norma verificando contemporaneamente la giurisprudenza
            di riferimento più attuale, nonché le delibere della Corte dei Conti su ciascun aspetto della normativa sulle società partecipate, così da
            essere sempre aggiornati sui cambi di orientamento e sulle migliori prassi da seguire.
            <br />
            <br />
            L’obiettivo è quello di consentire agli addetti del settore di poter contare su uno strumento pratico e allo stesso tempo facilmente
            accessibile, che consenta di capire velocemente come approcciare ogni caso concreto e come applicare le singole norme.
        </div>
    );
}
