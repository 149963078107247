import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { Banner, Button, Detail, Grid, Card, H2, H4, H5, Modal, Row, TreeView, TreeViewItem, Icon } from "@maggioli-design-system/react";
import Search from "../../components/Search/Search";
import "./TestoSingle.css";
import { getChildrenTree, getNews, getProdotto, searchRedaVoci } from "../../services/Redazionale";
import { cacID, checkAccess, getCookie, getDescription, getProduct, productExists, products } from "../../utils/Constants";
import { IHandleLoader } from "../../interfaces/IHandleLoader";
import { TailSpin, ThreeDots } from "react-loader-spinner";
import { scrollTop } from "../../utils/Functions";
import { IDocument } from "../../interfaces/IDocument";
import News from "./News";
import ArticleCard from "../../components/ArticleCard/ArticleCard";
import Voice from "./Voice";
import SearchDocs from "./SearchDocs";
import { checkUserAuthorization } from "../../services/Approfondimenti";
import { IUserRole } from "../../App";

interface Props {
    handleLoader: IHandleLoader;
    user: IUserRole;
}

interface ITreeElement {
    voce: any;
    hasChildren: boolean;
    id: string;
    docCount: number;
}

interface IChild {
    voce: any;
    hasChildren: any;
    id: string;
    parent: {
        id: string;
    };
}

const TestoSingle = ({ handleLoader, user }: Props) => {
    const { id } = useParams<Record<string, string>>();
    const history = useNavigate();
    const location = useLocation();

    const timeSearch: number = 700;
    const textLength: number = 0;

    const [tree, setTree] = useState<{ all: ITreeElement[]; filtered: ITreeElement[] }>({ all: [], filtered: [] });
    const [child, setChild] = useState<IChild[]>([]);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchDocText, setSearchDocText] = useState<string>("");
    const [searchVociText, setSearchVociText] = useState<string>("");
    const [news, setNews] = useState<IDocument[]>([]);
    const [selectedDoc, setSelectedDoc] = useState<string>("");
    const [showText, setShowText] = useState<boolean>(false);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    const [approAuth, setApproAuth] = useState<boolean>(false);
    const [subAuth, setSubAuth] = useState<boolean>(false);
    const [loadingVoci, setLoadingVoci] = useState<boolean>(false);

    const [writing, setWriting] = useState<boolean>(false);
    let timeID = useRef<any>(null);
    let time = useRef<number>(0);

    const loggedUser = JSON.parse(getCookie("loggedUser"));
    const refreshToken = getCookie("refresh_token");

    useEffect(() => {
        checkAuthorization();
        loadTree();
        loadNews();
        scrollTop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (writing) {
            return;
        }
        searchVociText.length > textLength && searchVoci();
        !firstLoad && searchDocuments();
        firstLoad && setFirstLoad(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [writing]);

    useEffect(() => {
        tree.all.forEach(async (t) => {
            t.hasChildren && (await loadChildren(t));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tree]);

    const loadTree = async () => {
        handleLoader.setShowLoader(true);
        const res = await getProdotto(id);
        handleLoader.setShowLoader(false);
        if (!res || (res.status && res.status !== 200)) {
            return;
        }
        setTree({ all: res, filtered: res });
    };

    const loadChildren = async (childData, click = false) => {
        if (click && tree.all.map((t) => t.id).includes(childData.id)) {
            setSelectedDoc(childData.id);
        }

        setSearchDocText("");

        if (!childData.hasChildren) {
            scrollTop();
            history(`/testo/${id}/index/${childData.id}`);
            setModalVisible(false);
        }

        if (child.filter((c) => c.parent.id === childData.id).length > 0) {
            return;
        }

        const res = await getChildrenTree(childData.id);
        if (res && res.filter((r) => child.map((c) => c.id).includes(r.id)).length === 0) {
            setChild([...child, ...res]);
        }
    };

    const loadNews = async () => {
        const res = await getNews(id);
        setNews(res.slice(0, 6));
    };

    const children = (element) => {
        const data = child.filter((c) => c.parent.id === element.id);
        return data.map((d, i) => (
            <TreeViewItem key={i} text={d.voce} onCallback={() => loadChildren(d)}>
                {d.hasChildren ? children(d) : null}
            </TreeViewItem>
        ));
    };

    const onClickSeeNews = () => {
        setSearchDocText("");
        setModalVisible(false);
        history(`/testo/${id}`);
    };

    const noteAndNewsButtons = () => {
        if (getDescription(id) === null) {
            return "";
        }
        return (
            <Button
                className="flex-grow"
                variant="secondary-outline"
                onClick={() => {
                    setShowText(true);
                    setModalVisible(false);
                }}
            >
                Descrizione
            </Button>
        );
    };

    const searchComponent = (
        <Search className="mt-1" onChange={(e) => onChangeSearchVoci(e)} placeholder="Cerca articoli..." value={searchVociText} />
    );
    const downloadPDF = async () => {
        const element = window.document.createElement("a");
        element.href = "/files/Ultime_novita.pdf";
        element.download = `Ultime_novità.pdf`;
        window.document.body.appendChild(element);
        element.click();
    };

    const treeComponent = () => {
        if (tree.filtered.length === 0) {
            return (
                <div className="gap-1 grid z-0 p-3">
                    {searchComponent}
                    {loadingVoci ? (
                        <div className="mx-auto w-10 -my-5">
                            <ThreeDots type="ThreeDots" color="#0041B9" width={50} />
                        </div>
                    ) : (
                        <Banner status="warning" className="rounded-2xl mt-3">
                            <Detail>Non sono stati trovati articoli.</Detail>
                        </Banner>
                    )}
                </div>
            );
        }

        return (
            <div className="grid gap-0 pt-4">
                <H4 className="px-6">Provvedimenti</H4>
                <TreeView className="bg-adjust-tone shadow-none">
                    {selectedDoc !== "" && !tree.filtered.map((a) => a.id).includes(selectedDoc) && (
                        <>
                            {searchComponent}
                            {loadingVoci && (
                                <div className="mx-auto w-10 -my-5">
                                    <ThreeDots type="ThreeDots" color="#0041B9" width={50} />
                                </div>
                            )}
                        </>
                    )}

                    {tree.filtered.map((t, i) => {
                        const isRoot: boolean = tree.all.map((a) => a.id).includes(t.id);
                        if (isRoot) {
                            return (
                                <TreeViewItem key={i} text={t.voce} onCallback={() => loadChildren(t, true)}>
                                    {searchComponent}
                                    {loadingVoci && (
                                        <div className="mx-auto w-10 -my-5">
                                            <ThreeDots type="ThreeDots" color="#0041B9" width={50} />
                                        </div>
                                    )}
                                    {t.hasChildren ? children(t) : null}
                                </TreeViewItem>
                            );
                        }
                        return (
                            <TreeViewItem key={i} text={t.voce} onCallback={() => loadChildren(t, true)}>
                                {t.hasChildren ? children(t) : null}
                            </TreeViewItem>
                        );
                    })}
                </TreeView>
            </div>
        );
    };

    const onChangeSearchDoc = (e) => {
        const text: string = e.target.value;
        setSearchDocText(text);
        time.current = 0;

        setSearchVociText("");
        setWriting(true);
        manageTimer();
    };

    const searchDocuments = () => {
        if (searchDocText === "") {
            history(`/testo/${id}`);
            return;
        }
        history(`/testo/${id}/search?text=${searchDocText}&page=1&type=&orderByDate=true`);
    };

    const manageTimer = () => {
        if (timeID.current === null) {
            timeID.current = setInterval(function () {
                if (time.current < timeSearch) {
                    time.current = time.current + timeSearch;
                } else {
                    time.current = 0;
                    setWriting(false);
                    clearInterval(timeID.current);
                    timeID.current = null;
                }
            }, timeSearch);
        }
    };

    const onChangeSearchVoci = async (e) => {
        const text: string = e.target.value;
        setSearchVociText(text);

        if (text.length > textLength) {
            setWriting(true);
            manageTimer();
            return;
        }
        setTree({ all: tree.all, filtered: tree.all });
    };

    const searchVoci = async () => {
        setLoadingVoci(true);
        const res = await searchRedaVoci(parseInt(id), searchVociText, selectedDoc);
        setLoadingVoci(false);
        if (res) {
            setTree((p) => ({ ...p, filtered: res }));
        }
    };

    const checkAuthorization = async () => {
        if (!productExists(id)) {
            return;
        }
        if (loggedUser === null) {
            return;
        }

        const isAppro: boolean = loggedUser.type === "approfondimenti";
        if(isAppro) {
            const res = await checkUserAuthorization(loggedUser.id, getProduct(id).isbn);
            setApproAuth(res.status || user.isWorker);
        } else {
            const res2 = await checkAccess(id);
            setSubAuth(res2);
        }
    };

    const deniedAccess = () => {
        return (
            <Grid className="bg-adjust-tone-19 mobile:pt-0 gap-1">
                <Row>
                    <Icon name="status-error" size="large" />
                    <Grid className="gap-0">
                        <H4 className="">Impossibile visualizzare i contenuti per questo volume</H4>
                        <H5>Il tuo account non è autorizzato ad accedere a questo contenuto protetto.</H5>
                    </Grid>
                </Row>
            </Grid>
        );
    };

    if (!productExists(id)) {
        return (
            <Grid className="view-limit bg-adjust-tone-19 py-12 mobile:pt-0">
                <H2 className="">Volume non trovato</H2>
                <Link to="/">
                    <Button variant="primary-outline" icon="dashboard">
                        Torna alla home
                    </Button>
                </Link>
            </Grid>
        );
    }

    const customLoader = {
        setLoading: setLoading,
        loading: loading,
    };

    const goToLogin = () => {
        return (
            <div>
                {/* <Hr spacing="xxsmall" /> */}
                <Grid className="gap-2">
                    <H4>Per visualizzare i contenuti devi accedere</H4>
                    <Row>
                        <Link to="/login">
                            <Button variant="secondary-outline">Accedi</Button>
                        </Link>
                        <Link to="/register">
                            <Button>Registrati</Button>
                        </Link>
                    </Row>
                </Grid>
            </div>
        );
    };

    const canAccess = approAuth || subAuth;

    const context = () => {
        if (loggedUser === null && refreshToken === null) {
            return goToLogin();
        }
        if (!canAccess && !loading) {
            return deniedAccess();
        }
        if (location.pathname.includes("index")) {
            return <Voice handleLoader={handleLoader} loader={customLoader} />;
        }
        if (location.pathname.includes("news")) {
            return <News handleLoader={customLoader} />;
        }
        if (location.pathname.includes("search")) {
            return <SearchDocs handleLoader={handleLoader} loader={customLoader} prodID={parseInt(id)} checkAccess={canAccess} />;
        }
        return articles();
    };

    const articles = () => {
        if (!loading) {
            return (
                <div className={`grid grid-cols-1 large:grid-cols-2 my-3 mb-9 mobile:gap-4`}>
                    {news.map((d, i) => {
                        return (
                            <div key={i} onClick={() => d.ftype.toLowerCase() !== "pdf" && history(`/testo/${id}/news/${d.id}`)}>
                                <ArticleCard data={d} handleLoader={handleLoader} prodID={id} user={user} />
                            </div>
                        );
                    })}
                </div>
            );
        }
        return <div></div>;
    };

    return (
        <div>
            {/* <H2 className="text-center mt-10 tablet-max:my-12">{products.filter(p => p.id === id).pop().title}</H2> */}
            <Modal className="z-30" visible={showText} onCancel={() => setShowText(false)} maxWidth="1200" position="center" footer={false}>
                <H4>Annotato con la giurisprudenza e prassi</H4>
                {getDescription(id)}
            </Modal>

            <Modal
                className="desktop:hidden z-30"
                position="left"
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                maxWidth="1200"
                title="Indice"
                footer={false}
            >
                <div className="grid gap-4 auto-rows-min">
                    {noteAndNewsButtons()}
                    {treeComponent()}
                </div>
            </Modal>

            <div className="bg-adjust-tone-19 tablet:gap-x-12 grid large:max-w-screen-wide desktop-max:w-full mobile:pt-0 gap-0 mx-auto px-6 pt-5 py-12 relative template-testo-single min-h-screen">
                <div id="aside-menu" className="fixed w-[400px] z-10 top-0 bottom-0 pt-24 pb-16 overflow-auto px-1 tablet-max:hidden">
                    <Card className="w-full flex flex-col gap-0 shadow p-0 overflow-hidden">
                        <div className="p-8 pb-4">
                            <img
                                src={products.filter((p) => p.id === id).pop().img}
                                className="cursor-pointer"
                                onClick={() => onClickSeeNews()}
                                alt=""
                            />
                        </div>
                        <div className="gap-4 flex-row flex py-2 px-4">
                            {noteAndNewsButtons()}
                            {id === cacID && (
                                <Button
                                    className="flex-grow"
                                    icon="action-download"
                                    variant="secondary-outline"
                                    onClick={() => downloadPDF()}
                                    disabled={!canAccess}
                                >
                                    Novità sul codice
                                </Button>
                            )}
                        </div>
                        {treeComponent()}
                    </Card>
                </div>

                <div className="grid gap-y-8 gap-x-0 mobile:mt-0 mobile:gap-y-4 auto-rows-min desktop:col-start-2">
                    <Grid className="desktop:hidden col-span-2" gutter="small">
                        <Row className="mobile:mt-10">
                            <Button icon="document-book" onClick={() => setModalVisible(true)}>
                                Indice
                            </Button>
                            <Search onChange={(e) => onChangeSearchDoc(e)} placeholder="Cerca nei documenti..." />
                        </Row>
                    </Grid>
                    <Grid className="tablet-max:hidden">
                        <Row>
                            <Search onChange={(e) => onChangeSearchDoc(e)} placeholder="Cerca nei documenti..." />
                        </Row>
                    </Grid>
                    {loading && (
                        <div className="m-auto">
                            <TailSpin width={100} type="TailSpin" color="#00379E" />
                        </div>
                    )}
                    {context()}
                </div>
            </div>
        </div>
    );
};

export default TestoSingle;
