import React, { ReactElement, useState, ChangeEvent, useEffect } from "react";
import { Button, Card, Detail, Form, Grid, InputPassword, InputText, Row } from "@maggioli-design-system/react";
import Logo from "../../assets/images/LOGO_Norme_PA.png";
import { ISubmit } from "../../interfaces/ISubmit";
import { scrollTop } from "../../utils/Functions";
import { login } from "../../services/Approfondimenti";
import { useNavigate, Link } from "react-router-dom";
import { oneDayInSeconds, setCookie } from "../../utils/Constants";
import { Keycloak } from "keycloak-auth";

export default function LoginForm(): ReactElement {
    const history = useNavigate();
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [submit, setSubmit] = useState<ISubmit>({ status: "", text: "Accedi" });

    useEffect(() => {
        scrollTop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loginApprofondimenti = async () => {
        const res = await login(email, password);
        if (!res.status) {
            setSubmit({ text: res.message, status: "error" });
            window.setTimeout(() => setSubmit({ status: "", text: "Accedi" }), 1300);
            return;
        }

        const loggedUser = {
            id: res.user_id,
            type: "approfondimenti",
            email: email,
            login_date: Date.now(),
        };
        setCookie("loggedUser", JSON.stringify(loggedUser), oneDayInSeconds);
        setSubmit({ text: "Accesso effettuato!", status: "success" });
        window.setTimeout(() => window.location.reload(), 500);
    };

    const onClickLogin = async () => {
        if (email === "" || password === "") {
            setSubmit({ text: "Inserire email e password", status: "error" });
            window.setTimeout(() => setSubmit({ status: "", text: "Accedi" }), 1500);
            return;
        }

        await loginApprofondimenti();
    };

    return (
        <Card className="shadow-lg hover:shadow-xl bg-adjust-tone-18 gap-2 tablet:w-[800px] p-8 tablet:grid-cols-6">
            <Form
                gutter="small"
                className="col-span-3"
                onSubmit={(e: ChangeEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    onClickLogin();
                }}
            >
                <img src={Logo} alt="logo" className="max-w-12 mx-auto -mt-10" />

                <Grid gutter="small" className="-mt-5">
                    <InputText
                        placeholder="Inserisci l'e-mail..."
                        icon="document-email"
                        value={email}
                        onChange={(e: ChangeEvent<HTMLFormElement>) => setEmail(e.target.value)}
                    />

                    <InputPassword
                        onChange={(e: ChangeEvent<HTMLFormElement>) => setPassword(e.target.value)}
                        value={password}
                        placeholder="Inserisci la password..."
                    />
                </Grid>

                <Button className="m-auto" variant="primary-outline" onClick={() => window.location.replace(Keycloak.auth().createLoginString())}>
                    Abbonato servizi online
                </Button>

                <Button horizontalPadding="large" variant={submit.status} type="submit">
                    {submit.text}
                </Button>

                <Row lastChild="to-right">
                    <Link to="/recover">
                        <Button size="small" variant="secondary-outline">
                            Recupera password
                        </Button>
                    </Link>

                    <Button size="small" variant="secondary" onClick={() => history("/register")}>
                        Registrati
                    </Button>
                </Row>
            </Form>

            <Detail className="text-left -my-1 text-base col-span-3 mobile:mt-5 tablet:p-5 tablet:mt-20" onClick={() => history("/guida")}>
                <strong>Nota:</strong> se sei abbonato ad un servizio internet o a SistemaPA, clicca su ABBONATO SERVIZI ONLINE, inserisci le tue
                credenziali di abbonamento e clicca su ACCEDI. <br />
            </Detail>
        </Card>
    );
}
