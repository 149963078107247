import { SUB_URL } from "../utils/Constants";

export async function getForms(token: string, callback: Function) {
    const data = { token: token };

    await fetch("https://privacy.maggiolicloud.it/get/form", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .then((data) => {
            callback(data);
        })
        .catch((error) => console.log(error));
}

export async function savePrivacyData(payload: any, actionURL: string, callback: Function) {
    await fetch(actionURL, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        body: JSON.stringify(payload),
    })
        .then((response) => response.json())
        .then((data) => {
            callback(data);
        })
        .catch((error) => console.log(error));
}

export async function sendDataToSubscriptions(data: any, callback: Function) {
    await fetch(`${SUB_URL}email`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: getSubscriptionHeaders(),
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .then((data) => {
            callback(data);
        })
        .catch((error) => console.log(error));
}

export async function sendDataNoPrivacy(data: any, callback: Function) {
    await fetch(`${SUB_URL}email/only`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: getSubscriptionHeaders(),
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .then((data) => {
            callback(data);
        })
        .catch((error) => console.log(error));
}

export async function getMyIP() {
    return await fetch(`${SUB_URL}helper/ip`, {
        method: "GET",
        headers: getSubscriptionHeaders(),
    });
}

const getSubscriptionHeaders = () => {
    const headers = new Headers();
    const user: string = process.env.REACT_APP_SUB_USER;
    const password: string = process.env.REACT_APP_SUB_PASS;
    headers.set("Authorization", "Basic " + btoa(`${user}:${password}`));
    headers.set("Content-Type", "application/json");
    return headers;
};
