import { Grid } from "@maggioli-design-system/react";

import React, { ReactElement } from "react";
import { IHandleLoader } from "../../interfaces/IHandleLoader";
import LoginForm from "../../components/LoginForm/LoginForm";

interface Props {
    handleLoader: IHandleLoader;
}

export default function Login({ handleLoader }: Props): ReactElement {
    return (
        <div className="bg-adjust-tone-19 py-12 px-4 mobile:pt-4">
            <Grid columns="3" className="gap-10 mobile:gap-4 view-limit grid-tmpl-home mobile:grid-cols-1" rows="fit-vertically">
                <div></div>
                <LoginForm />
            </Grid>
        </div>
    );
}
