import React, { useState, useEffect } from "react";
import { Banner, Button, Card, Detail, Grid, H4, H6, Hr, Modal, Row, Tab, Tag, TabItem } from "@maggioli-design-system/react";
import { downloadDocument, getRimandi } from "../../services/Redazionale";
import { IHandleLoader } from "../../interfaces/IHandleLoader";
import { TailSpin } from "react-loader-spinner";
import Rimando from "../Rimando/Rimando";
import { docTypes, getCookie } from "../../utils/Constants";
import { IDocument } from "../../interfaces/IDocument";
import { IRimando } from "../../interfaces/IRimando";
import { textHighlight, htmlHighLights } from "../../utils/Helper";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";

interface Props {
    data: IDocument;
    handleLoader: IHandleLoader;
    showHighlights?: boolean;
    open?: boolean;
    checkAccess?: boolean;
}

const ArticoloOpened = ({ data, handleLoader, showHighlights = false, open = true, checkAccess = false }: Props) => {
    const [docHtml, setDocHtml] = useState<string>("");
    const [rimandi, setRimandi] = useState<IRimando[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [docType, setDocType] = useState<string>(docTypes[0]);
    const [linkedDoc, setLinkedDoc] = useState<boolean>(false);
    const [errorModal, setErrorModal] = useState<boolean>(false);
    const [opened, setOpened] = useState<boolean>(open);

    const history = useNavigate();

    useEffect(() => {
        loadSubDoc();
        setDocType(docTypes[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const loadSubDoc = async () => {
        setLoading(true);
        const resRimandi = await getRimandi(data.id);
        const res2 = await downloadDocument(data.id);

        setLoading(false);
        resRimandi && setRimandi(resRimandi.rimandiList);
        res2 && setDocHtml(await res2.text());
    };

    const downloadRimando = async () => {
        handleLoader.setShowLoader(true);
        const res = await downloadDocument(data.id);
        handleLoader.setShowLoader(false);
        if (!res) {
            return;
        }

        const element = window.document.createElement("a");
        element.href = URL.createObjectURL(res);
        element.download = `${data.descrizione}.${data.ftype.toLowerCase()}`;
        window.document.body.appendChild(element);
        element.click();
    };

    const tabComponent = (
        <div>
            <Tab className="bg-adjust-tone-19 -my-2 w-auto rounded-full mobile:w-11/12">
                {docTypes
                    .filter((t) => rimandi.filter((r) => r.type === t).length > 0)
                    .map((t, i) => (
                        <TabItem key={i} className="rounded-full" active={docType === t} onClick={() => setDocType(t)}>
                            {`${t}`}
                        </TabItem>
                    ))}
            </Tab>
        </div>
    );

    const onClickOpenArticlo = () => {
        const loggedUser = JSON.parse(getCookie("loggedUser"));
        if (loggedUser === null) {
            history("/login");
            return;
        }

        if (checkAccess) {
            setOpened(true);
        } else {
            setErrorModal(true);
        }
    };

    const rimandiToShow = rimandi.filter((r) => r.type === docType);
    const downloadButton = <Button size="small" icon="action-download" onClick={() => downloadRimando()} />;
    const showButtonIcon: string = linkedDoc ? "navigation-hide" : "navigation-show";

    if (rimandi.length > 0 && rimandiToShow.length === 0) {
        if (docType !== docTypes[2]) {
            docType === docTypes[0] ? setDocType(docTypes[1]) : setDocType(docTypes[2]);
        }
    }

    return (
        <Card>
            <div className="flex gap-2 items-start">
                <H4 className="flex-grow">
                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textHighlight(data.descrizione, data)) }} />
                </H4>
                <div className="flex gap-2 mobile:hidden flex-shrink-0">
                    <Tag chip>{data.type.charAt(0).toUpperCase() + data.type.slice(1)}</Tag>
                </div>
            </div>
            {data.descrdoc !== null && <H6 className="-mt-8">{data.descrdoc}</H6>}

            <Row className="tablet:hidden -mt-5" lastChild="to-right">
                <div>
                    <Tag chip>{data.type.charAt(0).toUpperCase() + data.type.slice(1)}</Tag>
                </div>
            </Row>

            {loading && <TailSpin width={50} color="#0041B9" />}

            {!loading && showHighlights && <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textHighlight(data.titolo, data)) }} />}

            {!loading && !showHighlights && <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.titolo) }} />}

            {!loading && showHighlights && data.highlights !== undefined && data.highlights.length > 0 && (
                <>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize("[" + data.highlights.map((h) => `..${textHighlight(h, data)}..`).join("<br/>") + "]"),
                        }}
                    />
                </>
            )}

            {docHtml !== "" && !loading && !opened && (
                <div>
                    <Button onClick={() => onClickOpenArticlo()} variant="primary-outline">
                        Apri articolo
                    </Button>
                </div>
            )}
            {docHtml !== "" && !loading && opened && (
                <Grid rows="fit-vertically">
                    {data.ftype.toLowerCase() === "pdf" ? (
                        <Row>
                            <Detail>Scarica il documento</Detail>
                            {downloadButton}
                        </Row>
                    ) : (
                        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlHighLights(docHtml, data)) }} />
                    )}

                    {rimandi.length > 0 && (
                        <Grid>
                            <Hr spacing="xxsmall" />

                            <div className="flex gap-2">
                                {rimandi.length > 0 && (
                                    <div className="flex-shrink-0 flex gap-2 -mt-2">
                                        <Button variant="primary-outline" icon={showButtonIcon} onClick={() => setLinkedDoc(!linkedDoc)}>
                                            {linkedDoc ? "Nascondi " : "Vedi "}documenti collegati
                                        </Button>
                                    </div>
                                )}
                            </div>

                            {linkedDoc && tabComponent}

                            {rimandiToShow.length === 0 && linkedDoc && (
                                <Banner className="rounded-2xl">
                                    <Detail>Non ci sono documenti di {docType} da mostrare.</Detail>
                                </Banner>
                            )}

                            {linkedDoc &&
                                rimandiToShow.map((r, i) => (
                                    <div key={i}>
                                        <Rimando handleLoader={handleLoader} data={r} />
                                    </div>
                                ))}
                        </Grid>
                    )}
                </Grid>
            )}
            <Modal className="z-40" visible={errorModal} onCancel={() => setErrorModal(false)} position="center" footer={false}>
                <Grid className="mt-7" gutter="small">
                    <Detail>
                        <H4>Impossibile visualizzare i contenuti per questo volume.</H4>
                        <Detail>Il tuo account non è autorizzato ad accedere a questo contenuto protetto.</Detail>
                    </Detail>
                    <div>
                        <Button variant="primary-outline" onClick={() => setErrorModal(false)}>
                            Chiudi
                        </Button>
                    </div>
                </Grid>
            </Modal>
        </Card>
    );
};

export default ArticoloOpened;
