import React from "react";
import clsx from "clsx";
import { Card } from "@maggioli-design-system/react";
import { IProdotto } from "../../interfaces/IProduct";

interface Props {
    className: string;
    data: IProdotto;
}

const TestoNormativoCard = ({ className, data }: Props) => {
    return (
        <Card className={clsx(className, "gap-2 shadow hover:shadow-lg cursor-pointer tablet:h-64")}>
            <img src={data.img} alt="book" />
            {/* <Hr className="m-0"/>
            <Paragraph className="text-center"><b>{data.title}</b></Paragraph> */}
        </Card>
    );
};

export default TestoNormativoCard;
