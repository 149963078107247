import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Banner, Button, Detail, Form, Grid, H2, InputText, InputPassword, Radio } from "@maggioli-design-system/react";
import { IHandleLoader } from "../../interfaces/IHandleLoader";
import { emailParams, NORME_PA_URL } from "../../utils/Constants";
import { emailValidation, passwordValidation } from "../../utils/Validator";
import { getForms, getMyIP, sendDataToSubscriptions } from "../../services/Privacy";
import { newUser } from "../../services/Approfondimenti";
import { ISubmit } from "../../interfaces/ISubmit";
import { scrollTop } from "../../utils/Functions";
import DOMPurify from "dompurify";

interface Props {
    handleLoader: IHandleLoader;
}

const TOKEN: string =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJwcml2YWN5Lm1hZ2dpb2xpY2xvdWQuaXQiLCJhdWQiOjEsImlhdCI6MTYzNjQ0OTY0NSwibmJmIjoxNjM2NDQ5NjQ1LCJleHAiOjE5NTE4MDk2NDUsImRhdGEiOnsiaWQiOjc4LCJzaXRlX2lkIjoxMDZ9fQ.S-6zYnMqP5wSZtYLiMh_6HaY-cl5Y9-QF5ckoszV5E4";
const INIT_TEXT: string = "Invia";
const INIT_STAT: string = "";

export default function Register({ handleLoader }: Props): ReactElement {
    const [form, setForm] = useState<any>(null);
    const [commerciale, setCommerciale] = useState<string>(null);
    const [submit, setSubmit] = useState<ISubmit>({ text: INIT_TEXT, status: INIT_STAT });

    useEffect(() => {
        scrollTop();
        handleLoader.setShowLoader(true);
        getForms(TOKEN, (result: any) => {
            handleLoader.setShowLoader(false);
            if (result.form.length > 0) {
                setForm(result.form.filter((elem) => elem.nome === "registrazione").pop());
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const privacyHandler = (value: string, privacyName: string) => {
        switch (privacyName) {
            case "commerciale":
                setCommerciale(value);
                break;
            default:
                break;
        }
    };

    const submitForm = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (commerciale === null) {
            alert("Tutti i campi sono obbligatori.");
            return;
        }

        const resIP = await getMyIP();
        if (!resIP.ok) {
            setSubmit({ text: "Errore nel recupero dell'IP", status: "error" });
            return;
        }
        const dataIP = await resIP.json();

        const formData = new FormData(e.target);
        const data = {};
        for (const [key, value] of formData.entries()) {
            data[key] = value;
        }
        data["token"] = TOKEN;
        data["ip"] = dataIP.ip;

        if (!passwordValidation(data["password"], data["password2"])) {
            alert("La password è obbligatoria e deve essere lunga almeno 6 caratteri. Le password devono coincidere.");
            return;
        }
        if (!emailValidation(data["email"])) {
            alert("Email non valida.");
            return;
        }

        const res = await newUser(data["nome"], data["cognome"], data["email"], data["password"], data["password2"]);
        if (!res.status) {
            setSubmit({ text: res.message, status: "error" });
            window.setTimeout(() => setSubmit({ text: INIT_TEXT, status: INIT_STAT }), 3000);
            return;
        }

        const payload = {
            to: formData.get("email"), // email amministrazione
            from: "no-reply-siti-web@maggiolieditore.it", //email no replay sito
            fromName: "NormePA", //nome sito
            replyTo: formData.get("email"), //email inserita dall'utente
            subject: "Benvenuto in NormePA",
            message: `<p>Salve ${formData.get("nome")} ${formData.get("cognome")}, </p><p>
                        benvenuto al nostro portale di NormePA.</p>
                        <p>Per abilitare il tuo account è necessario cliccare sul link sottostante:<br>
                        ${NORME_PA_URL}/activate/${res.data}</p><br>
                        <p>Buona navigazione,<br>
                        Maggioli Editore.</p>`,
            privacy: {
                ...data,
            },
            params: {
                ...emailParams,
            },
        };

        handleLoader.setShowLoader(true);
        sendDataToSubscriptions(payload, (result: any) => {
            handleLoader.setShowLoader(false);
            if (result.response_status) {
                setSubmit({ text: "Account creato con successo!", status: "success" });
                sessionStorage.setItem("thankYouType", "reg");
                window.setTimeout(() => window.location.replace("/login"), 2000);
            }
        });
    };

    const privacyFields = () => {
        return form.privacy.map((elem, index: number) => (
            <div key={index}>
                <Detail className="privacy-consent" htmlTag={"div"}>
                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(elem.description) }} />
                    {elem.fields.map((item, i: number) => (
                        <Radio
                            key={i}
                            name={item.name}
                            value={item.options.value}
                            onChange={(e: ChangeEvent<HTMLFormElement>) => privacyHandler(e.target.value, item.name)}
                        >
                            {item.options.text}
                        </Radio>
                    ))}
                </Detail>
            </div>
        ));
    };

    return (
        <div className="bg-adjust-tone-19 py-12 px-4 mobile:pt-4">
            <Grid columns="3" className="gap-10 mobile:gap-4 view-limit grid-tmpl-home mobile:grid-cols-1" rows="fit-vertically">
                <Grid className="gap-0" rows="fit-vertically">
                    <H2 className="mb-4">Registrati a NormePA</H2>
                    <Detail>
                        Il tuo profilo verrà registrato su approfondimenti.maggiolicloud.it. Le relative credenziali potranno essere utilizzate per
                        accedere sia ad “Approfondimenti Libri Maggioli” che a "NormePA".
                    </Detail>
                </Grid>
                <Grid className="bg-adjust-tone-17 desktop:mx-24 rounded-3xl col-span-2">
                    {form !== null && (
                        <Form onSubmit={(e: ChangeEvent<HTMLFormElement>) => submitForm(e)} className="bg-adjust-tone-17 p-10 rounded-2xl">
                            {form.fields
                                .filter((elem) => elem.type !== "hidden")
                                .map((elem, index) =>
                                    elem.type !== "password" ? (
                                        <InputText key={index} name={elem.name} label={elem.label} placeholder={elem.placeholder} />
                                    ) : (
                                        <InputPassword key={index} name={elem.name} label={elem.label} placeholder={elem.placeholder} />
                                    ),
                                )}

                            {form.fields
                                .filter((elem) => elem.type === "hidden")
                                .map((elem, index) => (
                                    <input key={index} name={elem.name} value={elem.options.value} type={elem.type} />
                                ))}
                            <input name="trattamento" value="1" type="hidden" />
                            <input name="servizio" value={form.servizio} type="hidden" />
                            <Detail htmlTag={"div"}>
                                Cliccando su "Invia" dichiari di aver letto la
                                <a href="https://privacy.maggiolicloud.it/privacy/approfondimentimaggiolicloudit"> privacy policy.</a>
                            </Detail>

                            {privacyFields()}

                            {submit.status === "error" ? (
                                <Banner status="error" className="rounded-xl">
                                    <Detail>{submit.text}</Detail>
                                </Banner>
                            ) : (
                                <div>
                                    <Button variant={submit.status} type="submit">
                                        {submit.text}
                                    </Button>
                                </div>
                            )}
                        </Form>
                    )}
                </Grid>
            </Grid>
        </div>
    );
}
