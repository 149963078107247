import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Banner, Button, Detail, Grid, Paginator, HorizontalScroll, Tab, TabItem } from "@maggioli-design-system/react";
import { IDocument } from "../../interfaces/IDocument";
import ArticoloOpened from "../../components/ArticoloOpened/ArticoloOpened";
import { IHandleLoader } from "../../interfaces/IHandleLoader";
import { searchRedaDocuments } from "../../services/Redazionale";
import { docTypes, getMaxPage } from "../../utils/Constants";
import { scrollTop } from "../../utils/Functions";

interface Props {
    handleLoader: IHandleLoader;
    loader: {
        setLoading: Function;
        loading: boolean;
    };
    prodID: number;
    checkAccess: boolean;
}

export default function SearchDocs({ handleLoader, loader, prodID, checkAccess }: Props) {
    const history = useNavigate();
    const queryparams = new URLSearchParams(window.location.search);
    const type = queryparams.get("type");
    const text = queryparams.get("text");
    const page = queryparams.get("page");
    const sort = queryparams.get("orderByDate");
    const [currentPage, setCurrentPage] = useState<number>(parseInt(page));
    const [documents, setDocuments] = useState<IDocument[]>([]);
    const [maxPage, setMaxPage] = useState<number>(0);
    const [searchType, setSearchType] = useState<string>(type === "null" ? "" : type);
    const [isSorted, setIsSorted] = useState<boolean>(sort === "true");

    useEffect(() => {
        loadDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text, searchType, isSorted, currentPage]);

    useEffect(() => {
        loader.setLoading(true);
        setCurrentPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text]);

    useEffect(() => {
        setCurrentPage(1);
        history(`/testo/${prodID}/search?text=${text}&page=1&type=${type}&orderByDate=${isSorted}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSorted]);

    const loadDocuments = async () => {
        const searchResults: number = 20;
        const res = await searchRedaDocuments(prodID, text, currentPage - 1, searchResults, searchType, getSortType());
        loader.setLoading(false);
        if (res) {
            setDocuments(res.data);
            setMaxPage(getMaxPage(searchResults, res.count));
        }
    };

    const changeSearchType = (newType: string) => {
        const type: string = newType === searchType ? "" : newType;
        setCurrentPage(1);
        history(`/testo/${prodID}/search?text=${text}&page=1&type=${type}&orderByDate=${isSorted}`);
        setSearchType(type);
    };

    const getSortType = () => {
        let sortType: string = `&sort=_score,desc&sort=data,desc`;
        if (isSorted) {
            sortType = `&sort=data,desc&sort=articolo,asc&sort=bis,asc&sort=commaNumero,asc&sort=comma.keyword,asc&sort=bbis,asc`;
        }
        return sortType;
    };

    const tabComponent = (
        <div className="wide:py-5 wide:-my-5 flex gap-1">
            <Tab className="bg-adjust-tone-17 w-auto p-1 wide:p-3">
                {docTypes
                    .filter((d) => !d.toLowerCase().includes("normativa"))
                    .map((t, i) => (
                        <TabItem key={i} className="rounded-full" active={searchType === t} onClick={() => changeSearchType(t)}>
                            {t}
                        </TabItem>
                    ))}
                <div className="min-w-[2px] bg-adjust-tone-19 h-full flex-shrink" />
                <TabItem active={isSorted} onClick={() => setIsSorted(!isSorted)}>
                    Ordina per data
                </TabItem>
                <TabItem active={!isSorted} onClick={() => setIsSorted(!isSorted)}>
                    Ordina per pertinenza
                </TabItem>
            </Tab>
        </div>
    );

    const changePage = (newPage: number) => {
        if (newPage < 1 || newPage > maxPage) {
            return;
        }
        setCurrentPage(newPage);
        scrollTop();
        history(`/testo/${prodID}/search?text=${text}&page=${newPage}&type=${searchType}&orderByDate=${isSorted}`);
    };

    const scrollTopButton = (
        <div>
            <Button variant="primary-outline" icon="navigation-hide" onClick={scrollTop}>
                Torna su
            </Button>
        </div>
    );
    const showPaginator: boolean = documents.length > 0;
    const paginator = (
        <Paginator
            pages={maxPage}
            currentPage={currentPage}
            onClickPrev={() => changePage(currentPage - 1)}
            onClick={(v) => changePage(v)}
            className="px-3 pb-3 pt-0 bg-transparent mobile:-mx-6"
            onClickNext={() => changePage(currentPage + 1)}
        />
    );

    return (
        <Grid className="grid mobile:mt-0 mobile:gap-4 tablet-max:col-span-2" rows="fit-vertically">
            {documents.length === 0 && !loader.loading && (
                <Banner className="rounded-2xl">
                    <Detail>Nessun documento trovato.</Detail>
                </Banner>
            )}
            {!loader.loading && (
                <HorizontalScroll className="gap-0 mobile:-mx-6">
                    <div className="tablet:hidden min-w-6 h-6" />
                    {tabComponent}
                    <div className="tablet:hidden min-w-6 h-6" />
                </HorizontalScroll>
            )}
            {paginator}
            {!loader.loading &&
                documents.map((d, i) => (
                    <div key={i}>
                        <ArticoloOpened data={d} handleLoader={handleLoader} showHighlights={true} open={false} checkAccess={checkAccess} />
                    </div>
                ))}
            {showPaginator && !loader.loading && (
                <div className="grid gap-6">
                    {scrollTopButton}
                    {paginator}
                </div>
            )}
        </Grid>
    );
}
