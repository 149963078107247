import { reda } from "../utils/Constants";
import Singleton from "../utils/Singleton";

const getParam = (): RequestInit => {
    const myHeaders: Headers = new Headers();
    myHeaders.append("Authorization", `Bearer ${Singleton.getInstance()}`);
    myHeaders.append("Accept", `application/json`);
    myHeaders.append("Content-Type", `application/json`);

    const requestOptions: RequestInit = {
        method: "GET",
        headers: myHeaders,
    };

    return requestOptions;
};

export const getProdotto = async (id: number | string) => {
    const response = await fetch(`${reda.prodottoURL}${id}/voci-roots`, getParam());
    return response.json();
};

export const getTree = async (id: number | string) => {
    const response = await fetch(`${reda.treeURL}${id}`, getParam());
    return response.json();
};

export const getChildrenTree = async (id: string) => {
    const response = await fetch(`${reda.treeURL}${id}/children`, getParam());
    return response.json();
};

export const getRedaDocuments = async (id: number, size: number) => {
    const response = await fetch(`${reda.redaDocURL}${id}&size=${size}`, getParam());
    return response.json();
};

export const searchRedaDocuments = async (id: number, text: string, page: number = 1, size: number = 20, type: string = "", sort) => {
    const docType: string = type !== "" ? `&type=${type}` : "";
    const sortType: string = sort;
    const response = await fetch(`${reda.searchDocURL}query=${text}&prodotti=${id}&size=${size}${sortType}${docType}&page=${page}`, getParam());
    return {
        data: await response.json(),
        count: parseInt(response.headers.get("X-Total-Count")),
    };
};

export const advancedSearch = async (
    id: string,
    type: string,
    organ: string,
    sede: string,
    text: string,
    date: string,
    size: number = 10,
    page: number,
    sort: string = "&sort=_score,desc&sort=data,desc",
) => {
    let params: string = `type=${type}&prodotti=${id}&size=${size}&page=${page}`;
    params = text === "" ? params : params + `&query=${text}`;
    params = date === "" ? params : params + `&anno=${date}`;
    params = sede === "" ? params : params + `&sede=${sede}`;
    params = organ === "" ? params : params + `&organo=${organ}`;
    params = params + sort;
    const response = await fetch(`${reda.docURL}_search?${params}`, getParam());
    return response;
};

export const searchRedaVoci = async (id: number, text: string, idVoce: string) => {
    const response = await fetch(`${reda.treeURL}_search?prodottoId=${id}&query=${text}&idVoce=${idVoce}`, getParam());
    return response.json();
};

export const downloadDocument = async (id: number) => {
    const response = await fetch(`${reda.docURL}${id}/download`, getParam());
    return response.blob();
};

export const getDocuments = async (id: number) => {
    const response = await fetch(`${reda.docURL}${id}`, getParam());
    return response.json();
};

export const getRimandi = async (id: number) => {
    const response = await fetch(`${reda.rimandiURL}${id}`, getParam());
    return response.json();
};

export const getOrgani = async (id: string, type: string) => {
    const response = await fetch(`${reda.docURL}_search/facetedFilters/organo-nome?type=${type}&prodotti=${id}`, getParam());
    return response.json();
};

export const getSedi = async (id: string, type: string, organ: string = "") => {
    const organFilter: string = organ === "" ? "" : `&organo=${organ}`;
    const response = await fetch(`${reda.docURL}_search/facetedFilters/sede?type=${type}&prodotti=${id}&sort=KEY${organFilter}`, getParam());
    return response.json();
};

export const getNews = async (id: string) => {
    const response = await fetch(`${reda.prodottoURL}${id}/news`, getParam());
    return response.json();
};

export const getNewsLink = async (idDoc: string, idProd: string) => {
    const response = await fetch(`${reda.docURL}${idDoc}/rimandato-da?prodotto=${idProd}`, getParam());
    if (!response.ok) {
        return false;
    }
    return response.json();
};
