import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Grid, Paginator } from "@maggioli-design-system/react";
import { IDocument } from "../../interfaces/IDocument";
import ArticoloOpened from "../../components/ArticoloOpened/ArticoloOpened";
import { IHandleLoader } from "../../interfaces/IHandleLoader";
import { getRedaDocuments } from "../../services/Redazionale";
import { getMaxPage } from "../../utils/Constants";
import { scrollTop } from "../../utils/Functions";

interface Props {
    handleLoader: IHandleLoader;
    loader: {
        setLoading: Function;
    };
}

export default function Voice({ handleLoader, loader }: Props) {
    const { voiceid } = useParams<Record<string, string>>();

    const results: number = 15;

    const [page, setPage] = useState<number>(1);
    const [documents, setDocuments] = useState<IDocument[]>([]);
    const [maxPage, setMaxPage] = useState<number>(0);

    const start: number = (page - 1) * results;
    const end: number = (page - 1) * results + results;

    useEffect(() => {
        loadDocuments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [voiceid]);

    const loadDocuments = async () => {
        loader.setLoading(true);
        const res = await getRedaDocuments(parseInt(voiceid), 1000);
        loader.setLoading(false);
        res && setDocuments(res);
        res && setMaxPage(getMaxPage(results, res.length));
        setPage(1);
    };

    const scrollTopButton = (
        <Button variant="primary-outline" icon="navigation-hide" onClick={scrollTop}>
            Torna su
        </Button>
    );
    const showPaginator: boolean = maxPage > 1 && documents.length > 0;
    const paginator = (
        <Paginator
            pages={maxPage}
            currentPage={page}
            onClickPrev={() => page > 1 && setPage(page - 1)}
            onClick={(v) => setPage(v)}
            onClickNext={() => page < maxPage && setPage(page + 1)}
            className="px-3 pb-3 pt-0 bg-transparent"
        />
    );

    return (
        <Grid className="grid gap-8 mobile:mt-0 mobile:gap-4 tablet-max:col-span-2" rows="fit-vertically">
            {showPaginator && <div className="flex gap-2 justify-between items-center tablet-max:-mx-6">{paginator}</div>}
            {documents.slice(start, end).map((d, i) => (
                <div key={i}>
                    <ArticoloOpened data={d} handleLoader={handleLoader} />
                </div>
            ))}
            {showPaginator && (
                <div className="flex flex-wrap gap-2 justify-between items-center">
                    <div className="tablet-max:w-full">{scrollTopButton}</div>
                    <div className="flex gap-2 justify-between items-center tablet-max:-mx-6 tablet-max:w-full">{paginator}</div>
                </div>
            )}
        </Grid>
    );
}
