import React from 'react'


export default function TextuTupi() {
  return (
    <div>
        <b>
            (D.Lgs. 30 marzo 2001, n. 165) <br /><br />
        </b>

        Il Decreto Legislativo n. 165/2001 rappresenta la legge fondamentale per il pubblico impiego.
        Dopo la sua emanazione il testo del Decreto è stato integrato e modificato numerose volte e i continui
        interventi hanno reso la lettura e l’interpretazione complessa e, in alcuni casi, controversa. <br /><br />

        La versione telematica, costantemente aggiornata, riporta tempestivamente la prassi e giurisprudenza più
        significativa in calce ai singoli articoli del provvedimento. <br /><br />

        L’obiettivo è quello di agevolare gli addetti ai lavori nella corretta interpretazione e applicazione delle
        singole disposizioni. <br />

    </div>
  )
}