import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, Button, H3, H5, Row } from "@maggioli-design-system/react";
import { downloadDocument, getDocuments } from "../../services/Redazionale";
import { IDocument } from "../../interfaces/IDocument";
import { getCookie } from "../../utils/Constants";
import DOMPurify from "dompurify";

interface Props {
    handleLoader: {
        setLoading: Function;
    };
}

export default function News({ handleLoader }: Props) {
    const { newsid } = useParams<Record<string, string>>();
    const history = useNavigate();

    const [doc, setDoc] = useState<IDocument>(null);
    const [docHtml, setDocHtml] = useState<string>("");
    const loggedUser = JSON.parse(getCookie("loggedUser"));
    const isLogged: boolean = loggedUser !== null || getCookie("refresh_token") !== null;

    useEffect(() => {
        isLogged && loadDocument();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadDocument = async () => {
        handleLoader.setLoading(true);
        const res = await downloadDocument(parseInt(newsid));
        const res2 = await getDocuments(parseInt(newsid));
        handleLoader.setLoading(false);
        if (res && res2) {
            setDoc(res2);
            setDocHtml(await res.text());
        }
    };

    if (doc === null) {
        return <div></div>;
    }

    return (
        <div className="">
            <Row lastChild="to-right">
                <Button icon="action-back" onClick={() => history(-1)}>
                    Indietro
                </Button>
            </Row>
            <Card className="my-8 shadow  m-auto">
                <H3>{doc.descrizione}</H3>
                <H5>{doc.titolo}</H5>
                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(docHtml) }} />
            </Card>
        </div>
    );
}
