import { useEffect, useState } from "react";
import { H2, H4, Button, Row, Icon } from "@maggioli-design-system/react";
import Hero from "../../components/Hero/Hero";
import TestiList from "../../components/TestiList/TestiList";
import ArticlesList from "../../components/ArticlesList/ArticlesList";
import { IHandleLoader } from "../../interfaces/IHandleLoader";
import { flatProducts, getCookie, oneDayInSeconds, products, setCookie } from "../../utils/Constants";
import { getNews } from "../../services/Redazionale";
import { IDocument } from "../../interfaces/IDocument";
import { authorization, checkIP, enableIP, getGuestToken, getIP, introspect } from "../../utils/Functions";
import { Keycloak } from "keycloak-auth";
import { useLocation } from "react-router-dom";
import { IUserRole } from "../../App";

interface Props {
    handleLoader: IHandleLoader;
    user: IUserRole;
}

export interface IActiveSub {
    id_prodotto: string;
    sub_products: IActiveSub[];
}

const Home = ({ handleLoader, user }: Props) => {
    const noipCheck = localStorage.getItem("noIP");
    const ipEnabled = localStorage.getItem("ipEnabled");
    const checkIPCookie = getCookie("checkIP");
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const code: string = queryParameters.get("code");
    const refreshToken = getCookie("refresh_token");
    const loggedUser = getCookie("loggedUser");

    const [news, setNews] = useState<IDocument[]>([]);
    const [showBanner, setShowBanner] = useState<boolean>(noipCheck === null && checkIPCookie !== null && loggedUser === null);

    useEffect(() => {
        loadNews();
        if (loggedUser !== null) {
            return;
        }
        if (!code && loggedUser === null) {
            checkLoginSSO();
            return;
        }
        const data: string = refreshToken !== null ? refreshToken : code;
        refreshTokens(data, refreshToken !== null).then(async (accessToken) => {
            console.log(accessToken)
            if (!accessToken) {
                return;
            }

            const decodedToken = await introspect(accessToken);
            if (!decodedToken) {
                return;
            }

            if (ipEnabled === null && checkIPCookie !== null) {
                const ip = await getIP();
                const res = await enableIP(ip, accessToken, decodedToken.preferred_username, decodedToken.sub);
                if (!res) {
                    return;
                }
            }
            const data = await authorization(accessToken);
            if (!data) {
                return;
            }

            setCookie(
                "loggedUser",
                JSON.stringify({
                    type: "sub",
                    login_date: Date.now(),
                }),
                oneDayInSeconds,
            );
            window.location.href = "/";
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkLoginSSO = async () => {
        if (noipCheck !== null || checkIPCookie !== null) {
            return;
        }

        const ip = await getIP();
        const guestToken = await getGuestToken();
        if (!guestToken) {
            return;
        }
        const check = await checkIP(ip.ip, guestToken.access_token);

        if (check === null) {
            return;
        }
        if (!check) {
            localStorage.setItem("noip", "noip");
            return;
        }

        setCookie("checkIP", "checkIP", 1800);
        setShowBanner(true);
    };

    const refreshTokens = async (code: string, refresh: boolean = false) => {
        const dataKey = await Keycloak.auth().getToken(code, refresh);
        if (!dataKey.access_token) {
            return false;
        }

        setCookie("access_token", dataKey.access_token, dataKey.expires_in);
        setCookie("refresh_token", dataKey.refresh_token, dataKey.refresh_expires_in);

        return dataKey.access_token;
    };

    const loadNews = async () => {
        const temp = [];
        handleLoader.setShowLoader(true);

        for (let i = 0; i < products.length; i++) {
            const res = await getNews(products[i].id);
            temp.push({ prod: products[i], news: res });

            if (i === products.length - 1) {
                handleLoader.setShowLoader(false);
                setNews(temp);
            }
        }
    };

    const onClickRegisterLogin = () => {
        window.location.replace(Keycloak.auth().createLoginString());
    };

    const onClickHideBanner = () => {
        setShowBanner(false);
    };

    return (
        <>
            {showBanner && (
                <div className="bg-status-warning-18 p-10">
                    <div className="view-limit">
                        <Row lastChild="to-right">
                            <H4>
                                Per continuare ad accedere ai contenuti del sito registrati con il tuo indirizzo email di lavoro. Successivamente
                                potrai accedere con le credenziali in possesso. Se sei già registrato clicca qui sotto su Accedi.
                            </H4>
                            <Icon name="crud-cancel" className="cursor-pointer" onClick={() => onClickHideBanner()} />
                        </Row>
                        <Button className="mt-3" onClick={() => onClickRegisterLogin()}>
                            Registrati / Accedi
                        </Button>
                    </div>
                </div>
            )}
            <Hero />
            <div className="view-limit">
                <TestiList className="py-12" />
                <div className="py-8 grid gap-2">
                    <H2 className="mobile:px-2">Ultimi aggiornamenti</H2>
                    <div className="pt-2 mobile:pl-0 mobile:pt-0">
                        {news.map((n, i) => {
                            return (
                                <div key={i} className="mobile:px-2">
                                    <H4 className="mobile:pl-2">{n.prod.title}</H4>
                                    {n.news.length > 0 ? (
                                        <ArticlesList data={n.news.slice(0, 3)} handleLoader={handleLoader} prodID={n.prod.id} user={user} />
                                    ) : (
                                        <div className="pl-2 mb-5">Non ci sono news per questo prodotto.</div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
