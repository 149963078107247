import React from 'react'


export default function TextL214() {
  return (
    <div>
        <i>
        A cura di Serafina Frazzingaro, Giuseppe Raffaele Macrì e Pierluigi Rotili  <br /><br />
        </i>

        La Legge n. 241 del 1990 è un provvedimento di fondamentale importanza per le 
        Pubbliche Amministrazioni, in quanto disciplina il procedimento amministrativo ed il diritto di accesso ai documenti. <br /><br />

        Dopo la sua emanazione la Legge 241 è stata oggetto di continui interventi correttivi, 
        con l’obiettivo di favorire la massima semplificazione possibile e l’adeguamento ai processi di transizione digitale. <br /><br />
        La versione telematica, costantemente aggiornata, 
        riporta la giurisprudenza e la prassi più significativa in calce ai singoli articoli del provvedimento. <br /><br />

        L’obiettivo è quello di agevolare gli addetti ai lavori nella corretta interpretazione e applicazione delle singole disposizioni,
        soprattutto in relazione agli istituti di maggiore complessità (responsabile del procedimento, motivazione dei provvedimenti, 
        avvio e conclusione dei procedimenti, conferenze di servizi, silenzio-assenso, SCIA, diritto di accesso, ecc.)
    </div>
  )
}