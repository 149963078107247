import React, { ReactElement, useState } from "react";
import { Banner, Detail, Button, Modal, Grid, H3, InputText } from "@maggioli-design-system/react";
import { redeemCode } from "../../services/Approfondimenti";
import { getCookie, oneDayInSeconds, products, setCookie } from "../../utils/Constants";
import { IProdotto } from "../../interfaces/IProduct";

interface Props {
    visible: boolean;
    setVisible: Function;
}

export default function RedeemCodeModal({ visible, setVisible }: Props): ReactElement {
    const [code, setCode] = useState<string>("");
    const [message, setMessage] = useState<string>("");

    const onClickRedeemCode = async () => {
        const res = await redeemCode(code);
        if (!res.status) {
            setMessage(res.message);
            window.setTimeout(() => setMessage(""), 2000);
            return;
        }

        const availableProducts: IProdotto[] = products.filter((p) => p.isbn === res.data.isbn);
        if (availableProducts.length > 0) {
            const user = JSON.parse(getCookie("loggedUser"));
            user.book_redeemed.push({ title: availableProducts.pop().title, isbn: res.data.isbn });
            setCookie("loggedUser", JSON.stringify(user), oneDayInSeconds);
        }

        setMessage("Codice riscattato con successo!");
        window.setTimeout(() => window.location.reload(), 2000);
    };

    return (
        <Modal className="z-30" visible={visible} onCancel={() => setVisible(false)} position="center" footer={false}>
            <H3>Inserisci il codice d'accesso</H3>
            <Grid className="mt-7" gutter="small">
                <InputText value={code} onChange={(e) => setCode(e.target.value)} placeholder="Inserisci il codice..." />
                <div>
                    <Button onClick={() => onClickRedeemCode()}>Riscatta</Button>
                </div>

                {message !== "" && (
                    <Banner className="rounded-xl">
                        <Detail>{message}</Detail>
                    </Banner>
                )}
            </Grid>
        </Modal>
    );
}
