import React from 'react'


export default function TextCdsc() {
  return (
    <div>
        <b>
          (D.Lgs. n. 285/1992) <br /><br />
        </b>

        Il Decreto Legislativo 30 aprile 1992, n. 285 è la principale norma di riferimento per la circolazione stradale. <br />
        La presente versione telematica, <b>costantemente aggiornata</b>, riporta gli articoli del provvedimento collegandoli alla giurisprudenza correlata e agli orientamenti più significativi della prassi amministrativa.  <br /> <br />

        Inoltre, sempre in corrispondenza di ciascun articolo, sono riportate le norme collegate del <b>regolamento di esecuzione e di attuazione</b> (D.P.R. 495/1992). <br /> <br />
        
        L’obiettivo è quello di agevolare gli addetti ai lavori nella corretta interpretazione e applicazione delle singole disposizioni, nonché di avere a disposizione contenuti sempre aggiornati.
    </div>
  )
}