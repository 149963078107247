export const textHighlight = (text: any, data: any) => {
    if (data.highlights === undefined || data.highlights === 0 || data.highlights === null) {
        return text;
    }
    data.highlights.forEach((t: string) => {
        const match: Array<string> = t.match(/<em>(.*?)<\/em>/g);
        match !== null &&
            // eslint-disable-next-line array-callback-return
            match.map((v: string) => {
                const temp = /<em>(.*?)<\/em>/g.exec(v);
                if (temp !== null) {
                    text = typeof text !== "undefined" ? text.replace(temp[1], `<em class="search-highlight">${temp[1]}</em>`) : text;
                }
            });
    });
    return text;
};

export const htmlHighLights = (docHtml: any, data: any) => {
    if (docHtml === "") {
        return "";
    }
    if (data.highlights === undefined || data.highlights === 0 || data.highlights === null) {
        return docHtml;
    }
    let tempText = docHtml;
    data.highlights.forEach((t: string) => {
        const match: Array<string> = t.match(/<em>(.*?)<\/em>/g);
        match !== null &&
            // eslint-disable-next-line array-callback-return
            match.map((v: string) => {
                const temp = /<em>(.*?)<\/em>/g.exec(v);
                if (temp !== null) {
                    tempText = typeof tempText !== "undefined" ? tempText.replace(temp[1], `<em class="search-highlight">${temp[1]}</em>`) : tempText;
                }
            });
    });
    return tempText;
};
