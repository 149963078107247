import {
	Grid, 
	H2, 
    Button,
} from '@maggioli-design-system/react'

import { Link } from 'react-router-dom';
import React, { ReactElement } from 'react';

export default function NotFound(): ReactElement {
    
    return <div className="bg-adjust-tone-19 py-12 px-4 mobile:pt-4">
            <Grid className="text- gap-0 mobile:gap-4 view-limit mobile:grid-cols-1">
                <H2 className="mb-4">Errore 404 - Pagina non trovata</H2>
                <Link to="/"><Button variant="primary-outline" icon="dashboard">Torna alla home</Button></Link>
            </Grid>
	    </div>
}
