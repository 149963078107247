import React from "react";
import { useNavigate } from "react-router-dom";
import { IDocument } from "../../interfaces/IDocument";
import { IHandleLoader } from "../../interfaces/IHandleLoader";
import ArticleCard from "../ArticleCard/ArticleCard";
import { IUserRole } from "../../App";
interface Props {
    data: IDocument[];
    handleLoader: IHandleLoader;
    prodID: string;
    cols?: string;
    user: IUserRole;
}

const ArticlesList = ({ data, handleLoader, prodID, cols = "3", user }: Props) => {
    const history = useNavigate();

    return (
        <div className={`grid grid-cols-${cols} mobile:grid-cols-1 my-3 mb-9 mobile:gap-4`}>
            {data.map((d, i) => {
                return (
                    <div key={i} onClick={() => d.ftype.toLowerCase() !== "pdf" && history(`/article/${prodID}/${d.id}`)}>
                        <ArticleCard data={d} handleLoader={handleLoader} prodID={prodID} user={user} />
                    </div>
                );
            })}
        </div>
    );
};

export default ArticlesList;
