import { Routes, Route, BrowserRouter, Link } from "react-router-dom";

import { ActionsBar, ActionsBarItems, Header, HeaderLogo, HeaderMenu, Button, Modal, H4, Row, Grid } from "@maggioli-design-system/react";
import { useState, useEffect } from "react";
import Home from "./pages/Home/Home";
import SearchResults from "./pages/SearchResults/SearchResults";
import Article from "./pages/Article/Article";
// import Article2 from "./pages/Article2/Article2";
import TestoSingle from "./pages/TestoSingle/TestoSingle";
// import TestoGuest from "./pages/TestoGuest/TestoGuest";
import Footer from "./components/Layout/Footer/Footer";
import Contact from "./pages/Contact/Contact";
import Logo from "./assets/images/LOGO_Norme_PA.png";
// import TestoGuest2 from "./pages/TestoGuest2/TestoGuest2";

// Dependencies
import "typeface-karla";
import "typeface-roboto";
import "@maggioli-design-system/styles/dist/css/typography.css";
import "@maggioli-design-system/styles/dist/css/components.css";
import "@maggioli-design-system/icons/dist/mgg-icons.css";
import "@maggioli-design-system/design-tokens/dist/css/vars-rgb-channels.css";
import "./tailwind.generated.css";
import Login from "./pages/Login/Login";
import { IHandleLoader } from "./interfaces/IHandleLoader";
import Register from "./pages/Register/Register";
import NotFound from "./pages/NotFound/NotFound";
import Cookies from "./pages/Cookies/Cookies";
import RedeemCodeModal from "./components/RedeemCodeModal/RedeemCodeModal";
import Help from "./pages/Help/Help";
import Activate from "./pages/Activate/Activate";
import RecoverPassword from "./pages/RecoverPassword/RecoverPassword";
import ResetPassword from "./pages/ResetPassword /ResetPassword";
import { Keycloak } from "keycloak-auth";
import { NORME_PA_URL, getCookie, keycloakPayload } from "./utils/Constants";
import { deleteCookies } from "./utils/Functions";

Keycloak.init(keycloakPayload, NORME_PA_URL);

export interface IUserRole {
    isWorker: boolean;
    role: string;
}

interface IProps {
    user: IUserRole;
}

function App({ user }: IProps) {
    const oneDay: number = 24 * 60 * 60 * 1000;

    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [confirmLogout, setConfirmLogout] = useState<boolean>(false);

    const loggedUser = JSON.parse(getCookie("loggedUser"));
    const isAppro: boolean = loggedUser !== null && loggedUser.type === "approfondimenti";
    const refreshToken = getCookie("refresh_token");

    useEffect(() => {
        if (loggedUser !== null && loggedUser.login_date === undefined) {
            deleteCookies();
        }
        if (loggedUser !== null && Date.now() - loggedUser.login_date > oneDay) {
            deleteCookies();
        }
        if (loggedUser !== null && loggedUser.type === "sub" && refreshToken === null) {
            deleteCookies();
            window.document.location.reload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLoader: IHandleLoader = {
        showLoader: showLoader,
        setShowLoader: setShowLoader,
    };

    const loginButton = (
        <Link to="/login">
            <Button variant="secondary-outline" width="fill" onClick={() => setVisible(false)}>
                Accedi
            </Button>
        </Link>
    );
    const registerButton = (
        <Link to="/register">
            <Button variant="primary" width="fill" onClick={() => setVisible(false)}>
                Registrati
            </Button>
        </Link>
    );
    const advancedSearchButton = (
        <Link to="/search">
            <Button variant="primary" width="fill" onClick={() => setVisible(false)}>
                Ricerca avanzata
            </Button>
        </Link>
    );
    const redeemCodeButton = (
        <Button variant="primary" onClick={() => setShowModal(true)}>
            Inserisci il codice d'accesso
        </Button>
    );
    const logoutbutton = (
        <div>
            <Button
                variant="error-outline"
                icon="action-logout"
                width="fill"
                onClick={() => {
                    setConfirmLogout(true);
                    setVisible(false);
                }}
            />
        </div>
    );
    const helpLink = (
        <Link to="/guida">
            <Button variant="text" className="text-adjust-tone-04 px-2" horizontalPadding="none">
                Guida
            </Button>
        </Link>
    );

    const buttons = (modal: boolean = false) => {
        if (modal) {
            return (
                <Grid columns="1" className="mx-5" gutter="small">
                    {isAppro && redeemCodeButton}
                    {loggedUser === null ? loginButton : advancedSearchButton}
                    {loggedUser === null ? registerButton : logoutbutton}
                    {helpLink}
                </Grid>
            );
        }

        return (
            <Row>
                {helpLink}
                {isAppro && redeemCodeButton}
                {loggedUser === null ? loginButton : advancedSearchButton}
                {loggedUser === null ? registerButton : logoutbutton}
            </Row>
        );
    };

    const testoSingle = <TestoSingle handleLoader={handleLoader} user={user} />;

    return (
        <div className="bg-adjust-tone-19">
            {confirmLogout && (
                <div
                    style={{
                        backgroundColor: "grey",
                        width: "100%",
                        height: "100%",
                        backgroundRepeat: "repeat-y",
                        position: "fixed",
                        zIndex: 5,
                        opacity: "70%",
                    }}
                />
            )}
            <BrowserRouter>
                <Header lastChild="to-right" className="shadow z-20">
                    {<RedeemCodeModal visible={showModal} setVisible={setShowModal} />}

                    <ActionsBar position="right" boxShadow="box" notVisible={!confirmLogout}>
                        <H4>Sei sicuro di voler uscire?</H4>
                        <ActionsBarItems>
                            <Button variant="primary-outline" icon="crud-cancel" onClick={() => setConfirmLogout(false)}>
                                Annulla
                            </Button>
                            <Button
                                variant="error"
                                icon="action-logout"
                                onClick={() => {
                                    deleteCookies();
                                    window.location.reload();
                                }}
                            >
                                Esci
                            </Button>
                        </ActionsBarItems>
                    </ActionsBar>

                    <Link to="/">
                        <HeaderLogo src={Logo} className="w-36 h-auto" />
                    </Link>

                    <HeaderMenu width="inline" onClick={() => setVisible(!visible)}>
                        {buttons()}
                    </HeaderMenu>
                </Header>
                <Modal className="z-30" visible={visible} onCancel={() => setVisible(false)} position="left" footer={false} contentOnly={true}>
                    <Link to="/" onClick={() => setVisible(false)}>
                        <img src={Logo} alt="logo" className="max-w-10 mx-auto" />
                    </Link>
                    {buttons(true)}
                </Modal>

                {<RedeemCodeModal visible={showModal} setVisible={setShowModal} />}

                <Routes>
                    <Route path="/" element={<Home handleLoader={handleLoader} user={user} />} />
                    <Route path="/guida" element={<Help />} />
                    <Route path="/search" element={<SearchResults handleLoader={handleLoader} user={user} />} />

                    <Route path="/search/:idProdotto" element={<SearchResults handleLoader={handleLoader} user={user} />} />
                    <Route path="/article/:idProdotto/:id" element={<Article handleLoader={handleLoader} user={user} />} />

                    <Route path="/testo/:id" element={testoSingle} />
                    <Route path="/testo/:id/index/:voiceid" element={testoSingle} />
                    <Route path="/testo/:id/news/:newsid" element={testoSingle} />
                    <Route path="/testo/:id/search" element={testoSingle} />

                    <Route path="/recover" element={<RecoverPassword handleLoader={handleLoader} />} />
                    <Route path="/resetPassword/:code" element={<ResetPassword handleLoader={handleLoader} />} />
                    <Route path="/contatti" element={<Contact handleLoader={handleLoader} />} />
                    <Route path="/informativa-sui-cookies" element={<Cookies />} />
                    <Route
                        path="/login"
                        element={loggedUser === null ? <Login handleLoader={handleLoader} /> : <Home handleLoader={handleLoader} user={user} />}
                    />
                    <Route
                        path="/register"
                        element={loggedUser === null ? <Register handleLoader={handleLoader} /> : <Home handleLoader={handleLoader} user={user} />}
                    />
                    <Route path="/activate/:passCode" element={<Activate />} />
                    <Route element={<NotFound />} />
                </Routes>
                <Footer handleLoader={handleLoader} />
            </BrowserRouter>
        </div>
    );
}

export default App;
