import React, { useState } from "react";
import { Banner, Detail, Button, Card, H2, Grid, InputText } from "@maggioli-design-system/react";
import { IHandleLoader } from "../../interfaces/IHandleLoader";
import { passwordRecovery } from "../../services/Approfondimenti";
import { ISubmit } from "../../interfaces/ISubmit";
import { emailParams, NORME_PA_URL } from "../../utils/Constants";
import { sendDataNoPrivacy } from "../../services/Privacy";

interface Props {
    handleLoader: IHandleLoader;
}

export default function RecoverPassword({ handleLoader }: Props) {
    const initText: string = "Recupera password";
    const initStatus: string = "";

    const [email, setEmail] = useState<string>("");
    const [submit, setSubmit] = useState<ISubmit>({ text: initText, status: initStatus });

    const onClickRecoverPassword = async () => {
        if (email === "" || submit.status !== "") {
            return;
        }

        handleLoader.setShowLoader(true);
        const res = await passwordRecovery(email);
        if (!res.status) {
            setSubmit({ text: res.message, status: "error" });
            window.setTimeout(() => setSubmit({ status: initStatus, text: initText }), 2000);
            handleLoader.setShowLoader(false);
        }
        sendRecoverEmail(res.data);
    };

    const sendRecoverEmail = (code: string) => {
        const payload = {
            to: email, // email amministrazione
            from: "no-reply-siti-web@maggiolieditore.it", //email no replay sito
            fromName: "Norme PA", //nome sito
            replyTo: "no-reply-siti-web@maggiolieditore.it", //email inserita dall'utente
            subject: "Recupero password NormePA",
            message: `<p>Salve, </p><p>se ha ricevuto questa email è perchè qualcuno
                        ha richiesto il cambio password. <br> Se non si è sicuri di quello che
                        si sta facendo o se non si ha richiesto nessuna azione, eliminate questa
                        stessa email.</p><p>In alternativa per procedere al cambio password,
                        cliccare sul link sottostante:
                        <br><a href="${NORME_PA_URL}/resetPassword/${code}">Clicca qui</a></p>
                        <p>Nota: il link è valido solo nella giornata odierna.</p>
                        <p>Distinti saluti<br>Lo staff di Maggioli Editore</p>`,
            params: {
                ...emailParams,
            },
        };

        sendDataNoPrivacy(payload, (result: any) => {
            handleLoader.setShowLoader(false);
            if (result.response_status) {
                setSubmit({ text: "Richiesta inviata con successo!", status: "success" });
            }
        });
    };

    return (
        <div className="bg-adjust-tone-19 py-12 desktop:px-64 desktop-max:px-10 view-limit mobile:pt-4">
            <Grid className="gap-1 mobile:gap-4 view-limit grid-tmpl-home mobile:grid-cols-1" rows="fit-vertically">
                <H2 className="mx-auto">Recupera password</H2>
                <Card className="shadow-lg bg-adjust-tone-18">
                    Nel caso tu abbia dimenticato la tua password, potrai impostarne una nuova. <br />
                    Una volta avviata la procedura di recupero password, riceverai una email con un link di accesso alla pagina di reimpostazione
                    della password, accessibile solo tramite link generato nella email che ti abbiamo inviato.
                    <Banner status="warning" className="rounded-xl -my-3">
                        <Detail>La password verrà resettata anche su Approfondimenti in quanto le credenziali sono le stesse.</Detail>
                    </Banner>
                    <InputText
                        label="Email"
                        placeholder="Inserisci la tua email di registrazione..."
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <div>
                        <Button variant={submit.status} onClick={onClickRecoverPassword}>
                            {submit.text}
                        </Button>
                    </div>
                </Card>
            </Grid>
        </div>
    );
}
