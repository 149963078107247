import { ReactElement, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Button, Detail, H3, H4 } from "@maggioli-design-system/react";
import { activateUser } from "../../services/Approfondimenti";

export default function Activate(): ReactElement {
    const { passCode } = useParams<Record<string, string>>();

    const [activated, setActivated] = useState<boolean>(null);
    const [message, setMessage] = useState<string>("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        authorize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const authorize = async () => {
        const res = await activateUser(passCode);
        if (res.status) {
            setActivated(res.status);
            setMessage(res.message);
        }
    };

    return (
        <div className="padding-normal background-color-adjust-tone-19 my-10">
            <H3 className="text-center vertical-padding-xxsmall">NormePA</H3>
            {activated && (
                <div>
                    <H4 className="view-limit text-left mt-14">Grazie</H4>
                    <Detail className="view-limit vertical-padding-xxsmall">
                        Attivazione account avvenuta con successo! <br />
                        Maggioli ti dà il benvenuto al nostro portale di NormePA.
                        <br />
                        <br />
                        Buona navigazione,
                        <br />
                        Lo staff di Maggioli Editore.
                    </Detail>
                    <div className="view-limit vertical-padding-xxsmall mt-4">
                        <Link to="/login">
                            <Button variant="primary-outline">Accedi</Button>
                        </Link>
                    </div>
                </div>
            )}
            {activated === false && (
                <div>
                    <H4 className="view-limit text-left mt-14">Errore</H4>
                    <Detail className="view-limit vertical-padding-xxsmall">{message}</Detail>
                </div>
            )}
        </div>
    );
}
