import { Keycloak } from "keycloak-auth";
import { SUB_URL, clientID, clientSecret, getCookie, productsID, setCookie } from "./Constants";

const headers = new Headers();
const user: string = process.env.REACT_APP_SUB_USER;
const pass: string = process.env.REACT_APP_SUB_PASS;

export const loginByCredentials = async (username: string, password: string) => {
    const data: FormData = new FormData();
    data.append("id_prodotti", productsID.join(","));
    data.append("nomeutente", username);
    data.append("password", password);

    setAuthorization();

    const response = await fetch(`${SUB_URL}authenticate/index`, {
        method: "POST",
        headers: headers,
        body: data,
    });
    return response.json();
};

export const loginByIP = async (ip: string) => {
    const data: FormData = new FormData();
    data.append("id_prodotti", productsID.join(","));
    data.append("forward", null);

    setAuthorization();

    const response = await fetch(`${SUB_URL}authenticate/ip?remote_addr=${ip}`, {
        method: "POST",
        headers: headers,
        body: data,
    });
    return response.json();
};

export const getGuestToken = async () => {
    const formData = new URLSearchParams();
    const headerList = new Headers();
    headerList.append("Content-Type", "application/x-www-form-urlencoded");
    headerList.append("Accept", "*/*");
    formData.append("grant_type", "client_credentials");
    formData.append("client_id", clientID);
    formData.append("client_secret", clientSecret);
    const response = await fetch(`https://sso.maggioli.com/realms/maggioli/protocol/openid-connect/token`, {
        method: "POST",
        headers: headerList,
        body: formData,
    });
    if (!response.ok) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const checkIP = async (ip: string, token: string) => {
    const formData = new URLSearchParams();
    const headerList = new Headers();
    headerList.append("Content-Type", "application/x-www-form-urlencoded");
    headerList.append("Accept", "*/*");
    headerList.append("Authorization", "Bearer " + token);
    formData.append("remote_addr", ip);
    formData.append("id_prodotti", productsID.join(","));
    const response = await fetch(`${SUB_URL}sso/ip-check`, {
        method: "POST",
        headers: headerList,
        body: formData,
    });

    if (!response.ok) {
        return null;
    }
    const data = await response.json();
    return data;
};

export const enableIP = async (ip: string, token: string, email: string, subID: string) => {
    const formData = new URLSearchParams();
    const headerList = new Headers();
    headerList.append("Content-Type", "application/x-www-form-urlencoded");
    headerList.append("Accept", "*/*");
    headerList.append("Authorization", "Bearer " + token);
    formData.append("remote_addr", ip);
    formData.append("email", email);
    formData.append("sub", subID);
    formData.append("id_prodotti", productsID.join(","));
    const response = await fetch(`${SUB_URL}sso/ip-enable`, {
        method: "POST",
        headers: headerList,
        body: formData,
    });
    return response.ok;
};

export const authorization = async (access_token: string) => {
    const formSub = new URLSearchParams();
    const headersList = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": "Bearer " + access_token,
    };

    formSub.append("id_prodotti", productsID.join(","));

    const response = await fetch(`${SUB_URL}sso/user-info`, {
        method: "POST",
        body: formSub.toString(),
        headers: headersList,
    });
    if (!response.ok) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const introspect = async (access_token: string) => {
    const formSub = new URLSearchParams();
    const headersList = {
        "Accept": "*/*",
        "Authorization": "Bearer " + access_token,
        "Content-Type": "application/x-www-form-urlencoded",
    };
    formSub.append("client_id", clientID);
    formSub.append("client_secret", clientSecret);

    const response = await fetch("https://sso.maggioli.com/realms/maggioli/protocol/openid-connect/userinfo", {
        method: "POST",
        body: formSub,
        headers: headersList,
    });
    if (!response.ok) {
        return false;
    }
    const data = await response.json();
    return data;
};

export const getIP = async () => {
    setAuthorization();

    const response = await fetch(`${SUB_URL}helper/ip`, {
        method: "GET",
        headers: headers,
    });
    return response.json();
};

const setAuthorization = () => {
    headers.set("Authorization", "Basic " + btoa(`${user}:${pass}`));
};

// scrolla in cima alla pagina
export const scrollTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

export const deleteCookies = () => {
    const refreshToken = getCookie("refresh_token");
    setCookie("loggedUser", "", -1);
    setCookie("access_token", "", -1);
    setCookie("refresh_token", "", -1);
    Keycloak.auth().logout(refreshToken);
};
