import React, { ReactElement, useState, useEffect } from "react";
import { Card, H5, Hr, Row, Button, Detail } from "@maggioli-design-system/react";
import { downloadDocument, getDocuments } from "../../services/Redazionale";
import { ThreeDots } from "react-loader-spinner";
import { IHandleLoader } from "../../interfaces/IHandleLoader";
import { IRimando } from "../../interfaces/IRimando";
import DOMPurify from "dompurify";

interface Props {
    data: IRimando;
    handleLoader: IHandleLoader;
}

export default function Rimando({ data, handleLoader }: Props): ReactElement {
    const [rimando, setRimando] = useState<IRimando>(null);
    const [rimandoHtml, setRimandoHtml] = useState<string>("");
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setOpen(false);
    }, [data]);

    const downloadRimando = async () => {
        handleLoader.setShowLoader(true);
        const res = await downloadDocument(data.id);
        handleLoader.setShowLoader(false);
        if (!res) {
            return;
        }

        const element = window.document.createElement("a");
        element.href = URL.createObjectURL(res);
        element.download = `${data.descrizione}.${data.ftype}`;
        window.document.body.appendChild(element);
        element.click();
    };

    const loadRimando = async () => {
        setOpen(!open);
        if (open) {
            return;
        }

        setLoading(true);
        const res = await getDocuments(data.id);
        const res2 = await downloadDocument(data.id);
        setLoading(false);
        if (res && res2) {
            setRimando(res);
            setRimandoHtml(await res2.text());
        }
    };

    const artNumber = (): string => {
        if (data.type !== "normativa" || data.descrdoc === null) {
            return "";
        }
        if (data.descrdoc.toLowerCase().includes("articolo")) {
            return " - " + data.descrdoc.replace("Articolo", "Art.");
        } else {
            return " - " + data.descrdoc;
        }
    };

    const downloadButton = <Button size="small" icon="action-download" onClick={() => downloadRimando()} />;
    const rimandoIcon: string = open ? "navigation-hide" : "navigation-show";

    return (
        <Card className="bg-brand-maggioli-18 gap-2">
            <div className="flex gap-2">
                <H5 className="flex-grow">{`${data.descrizione}${artNumber()}`}</H5>
                <div className="flex-shrink-0 flex gap-2">
                    {!data.ftype.toLowerCase().includes("htm") && downloadButton}
                    <Button className="mobile:hidden" size="small" variant="primary-outline" icon={rimandoIcon} onClick={() => loadRimando()}>
                        {open ? "Chiudi" : "Vedi"}
                    </Button>
                </div>
            </div>

            <div>
                <Button
                    className="mobile:mr-10 tablet:hidden"
                    size="small"
                    variant="primary-outline"
                    icon={rimandoIcon}
                    onClick={() => loadRimando()}
                >
                    {open ? "Chiudi" : "Vedi"}
                </Button>
            </div>

            {<p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.titolo) }} />}
            {loading && <ThreeDots width={50} color="#0041B9" />}

            {rimando !== null && open && !loading && (
                <div className="-my-5">
                    <Hr />
                    {rimandoHtml !== "" && rimando.ftype.toLowerCase().includes("htm") && (
                        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(rimandoHtml) }} className="-my-4" />
                    )}

                    {rimando.ftype.toLowerCase() === "pdf" && (
                        <Row>
                            <Detail>Scarica il documento</Detail>
                            {downloadButton}
                        </Row>
                    )}
                </div>
            )}
        </Card>
    );
}
