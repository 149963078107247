import React from 'react'


export default function TextTuel() {
  return (
    <div>
        <b>
            (D.Lgs. n. 267/2000) <br /><br />
        </b>
        <i>
        A cura di Riccardo Carpino <br /><br />
        </i>

        Il Decreto Legislativo n. 267 del 2000 raccoglie l'ordinamento istituzionale e contabile degli Enti Locali. <br />
        In particolare, il provvedimento disciplina gli aspetti fondamentali relativi al funzionamento delle Amministrazioni Locali, 
        fra i quali gli organi di governo, il sistema elettorale, il ruolo e le responsabilità di amministratori e dipendenti, 
        sino a giungere ai processi di programmazione e gestione economico-finanziaria. <br /> <br />

        Dopo la sua emanazione il TUEL è stato oggetto di numerosi interventi correttivi, 
        che rendono complessa la sua analisi e interpretazione. <br /> <br />
        
        La versione telematica, costantemente aggiornata, riporta gli articoli del provvedimento annotati con la giurisprudenza 
        (amministrativa, civile, contabile e comunitaria) e gli orientamenti più significativi della prassi amministrativa. 
        Inoltre, in corrispondenza a ciascun articolo sono riportate le norme collegate, come la Legge n. 56/2014 
        (Città metropolitane, Province, Unioni e fusioni di Comuni), il D.Lgs. n. 235/2012 (Incandidabilità) e il D.Lgs. n. 39/2013 
        (Inconferibilità e incompatibilità). <br /><br />

        L’obiettivo è quello di agevolare gli addetti ai lavori nella corretta interpretazione e applicazione delle singole disposizioni.
    </div>
  )
}