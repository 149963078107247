import React from "react";
import { Link } from "react-router-dom";
import TestoNormativoCard from "../TestoNormativoCard/TestoNormativoCard";
import { H2 } from "@maggioli-design-system/react";
import clsx from "clsx";
import { products } from "../../utils/Constants";

interface Props {
    className: string,
}

const TestiList = ({ className }: Props) => {

    return (
        <div className={clsx(className, 'grid gap-2 mobile:gap-0')}>
            <H2 className="mobile:px-2">Consulta i testi normativi disponibili</H2>

            <div className="pt-4 justify-center desktop:gap-16 flex-wrap gap-4 grid tablet:grid-cols-2 mobile:px-2">
                { products.map((p, i) => <Link key={i} to={`/testo/${p.id}`}><TestoNormativoCard  data={p} className={className}/></Link>) }
            </div>
        </div>
    );
}

export default TestiList;