import React, { ChangeEvent, useState, useEffect } from "react";
import { Banner, Detail, Card, Grid, Form, H3, H4, InputText, Paginator, Row, Select, Button, Tab, TabItem } from "@maggioli-design-system/react";
import { useNavigate, useParams } from "react-router-dom";
import ArticleCard from "../../components/ArticleCard/ArticleCard";
import { advancedSearch, getOrgani, getSedi } from "../../services/Redazionale";
import { IHandleLoader } from "../../interfaces/IHandleLoader";
import { docTypes, getMaxPage, products } from "../../utils/Constants";
import { scrollTop } from "../../utils/Functions";
import { IDocument } from "../../interfaces/IDocument";
import { IUserRole } from "../../App";

interface Props {
    handleLoader: IHandleLoader;
    user: IUserRole;
}

interface IOrgan {
    key: string;
    value: string;
}

interface ISede {
    key: string;
}

const SearchResults = ({ handleLoader, user }: Props) => {
    const { idProdotto } = useParams<Record<string, string>>();
    const history = useNavigate();
    const queryparams = new URLSearchParams(window.location.search);
    const type = queryparams.get("type");
    const organ = queryparams.get("organ");
    const year = queryparams.get("anno") !== null ? queryparams.get("anno") : "";
    const text = queryparams.get("text") !== null ? queryparams.get("text") : "";
    const sede = queryparams.get("sede");
    const RESULTS: number = 10;

    const [docType, setDocType] = useState<string>(docTypes.filter((d) => d === type).length > 0 ? type : docTypes[0]);
    const [organs, setOrgans] = useState<IOrgan[]>([]);
    const [selOrgan, setSelOrgan] = useState<string>(organ);
    const [sedi, setSedi] = useState<ISede[]>([]);
    const [selSede, setSelSede] = useState<string>(sede);
    const [results, setResults] = useState<IDocument[]>([]);
    const [data, setData] = useState<string>(year);
    const [searchText, setSearchText] = useState<string>(text);
    const [showResults, setShowResults] = useState<boolean>(type !== null);
    const [maxPage, setMagPage] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [newProd, setNewProd] = useState<string>(
        products.filter((p) => p.id === idProdotto).length > 0 ? products.filter((p) => p.id === idProdotto).pop().title : products[0].title,
    );
    const [sort, setSort] = useState<boolean>(false);

    const newProductID: string = products.filter((p) => p.title === newProd).pop().id;

    useEffect(() => {
        const sessionResults: string = sessionStorage.getItem("results");
        const sessionMaxpage: string = sessionStorage.getItem("maxpage");
        const sessionPage: string = sessionStorage.getItem("page");
        if (sessionResults === null || sessionMaxpage === null || sessionPage === null) {
            return;
        }
        setResults(JSON.parse(sessionResults));
        setMagPage(JSON.parse(sessionMaxpage));
        setPage(JSON.parse(sessionPage));
        sessionStorage.clear();
    }, []);

    useEffect(() => {
        showResults && onClickSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort]);

    useEffect(() => {
        loadOrgani();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docType, newProd]);

    useEffect(() => {
        showResults && onClickSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        loadSedi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selOrgan]);

    const getSortType = () => {
        let sortType: string = `&sort=_score,desc&sort=data,desc`;
        if (sort) {
            sortType = `&sort=data,desc&sort=articolo,asc&sort=bis,asc&sort=commaNumero,asc&sort=comma.keyword,asc&sort=bbis,asc`;
        }
        return sortType;
    };

    const loadOrgani = async () => {
        const res = await getOrgani(newProductID, docType);
        const newOrgan: string = res.filter((o) => o.value === organ).length > 0 ? res.filter((o) => o.value === organ).pop().value : "";
        setSelOrgan(newOrgan);
        setOrgans(res);
    };

    const loadSedi = async () => {
        const organ: string =
            selOrgan !== "" && organs.filter((o) => o.value === selOrgan).length > 0 ? organs.filter((o) => o.value === selOrgan).pop().key : "";
        const res = await getSedi(newProductID, docType, organ);
        const newSede: string = res.filter((o) => o.value === sede).length > 0 ? res.filter((o) => o.value === sede).pop().value : "";
        setSelSede(newSede);
        setSedi(res);
    };

    const onChangeData = (value: number) => {
        if (isNaN(parseInt(value.toString().replaceAll("/", ""))) && value.toString() !== "") {
            return;
        }
        setData(value.toString());
    };

    const onClickSearch = async (e: ChangeEvent<HTMLFormElement> = null, showLoad: boolean = false) => {
        e !== null && e.preventDefault();
        if (showLoad) {
            handleLoader.setShowLoader(true);
            setPage(1);
        }
        const sortType: string = getSortType();
        const dataorgan: string = selOrgan !== "" && organs.length > 0 ? organs.filter((o) => o.value === selOrgan).pop().key : "";
        const res = await advancedSearch(newProductID, docType, dataorgan, selSede, searchText, data, RESULTS, showLoad ? 0 : page - 1, sortType);
        const resData = await res.json();
        handleLoader.setShowLoader(false);

        history(`/search/${newProductID}?type=${docType}&organ=${selOrgan}&sede=${selSede}&anno=${data}&text=${searchText}`);
        setMagPage(getMaxPage(RESULTS, parseInt(res.headers.get("x-total-count"))));
        setResults(resData);
        setShowResults(true);
    };

    const margin: string = "mx-52 tablet:mx-20 mobile:mx-5";
    const labelOrgan: string = docType === "giurisprudenza" ? "Organo" : "Tipologia di provvedimento";
    const labelSede: string = "Sede";

    const searchForm = (
        <Card className={margin}>
            <Row lastChild="to-right">
                <H3>Ricerca avanzata</H3>
                <Button variant="primary-outline" icon="action-back" onClick={() => history(-1)} disabled={history.length < 3}>
                    Indietro
                </Button>
            </Row>
            <Grid columns="4">
                <Select
                    label="Seleziona il provvedimento"
                    onChange={(e) => setNewProd(e.target.value)}
                    data={products.map((p) => p.title)}
                    value={newProd}
                />
                <Select label="Tipologia" data={docTypes.map((d) => d)} onChange={(e) => setDocType(e.target.value)} value={docType} />
            </Grid>
            <Form columns="4" onSubmit={(e: ChangeEvent<HTMLFormElement>) => onClickSearch(e, true)}>
                <Select
                    label={labelOrgan}
                    onChange={(e) => setSelOrgan(e.target.value)}
                    data={["", ...organs.map((o) => o.value)]}
                    value={selOrgan}
                />
                <Select label={labelSede} data={["", ...sedi.map((s) => s.key)]} onChange={(e) => setSelSede(e.target.value)} value={selSede} />
                <InputText label="Anno (aaaa)" value={data} placeholder="Inserisci l'anno..." onChange={(e) => onChangeData(e.target.value)} />
                <InputText
                    label="Numero o testo"
                    placeholder="Cerca per n. documento o testo generico..."
                    onChange={(e) => setSearchText(e.target.value)}
                    value={searchText}
                ></InputText>
                <div>
                    <Button icon="data-search" type="submit">
                        Cerca
                    </Button>
                </div>
            </Form>
        </Card>
    );

    const paginator = maxPage > 1 && (
        <Paginator
            pages={maxPage}
            currentPage={page}
            onClickNext={() => page < maxPage && setPage(page + 1)}
            onClickPrev={() => page > 1 && setPage(page - 1)}
            onClick={(v) => setPage(v)}
        />
    );

    const onClickArticle = (article: IDocument) => {
        if (article.ftype.toLowerCase() !== "pdf") {
            sessionStorage.setItem("results", JSON.stringify(results));
            sessionStorage.setItem("maxpage", maxPage.toString());
            sessionStorage.setItem("page", page.toString());
            history(`/article/${newProductID}/${article.id}`);
        }
    };

    return (
        <div className="my-10 ">
            {searchForm}

            {results.length === 0 && showResults && (
                <div className={margin}>
                    <Banner className={`mt-9 rounded-2xl`}>
                        <Detail>Non sono stati trovati documenti.</Detail>
                    </Banner>
                </div>
            )}

            {results.length > 0 && (
                <div className={`mt-14 ${margin}`}>
                    <H4 className="text-center">Risultati della ricerca</H4>
                    <Row lastChild="to-right" className="mobile:hidden">
                        {paginator}
                        {text !== "" ? (
                            <Tab className="bg-adjust-tone-17 -my-2 w-auto rounded-full mobile:w-11/12">
                                <TabItem className="rounded-full" active={sort} onClick={() => setSort(sort ? false : true)}>
                                    Ordina per data
                                </TabItem>
                                <TabItem className="rounded-full" active={!sort} onClick={() => setSort(sort ? false : true)}>
                                    Ordina per pertinenza
                                </TabItem>
                            </Tab>
                        ) : (
                            <div></div>
                        )}
                    </Row>
                    <Grid className="tablet:hidden" gutter="small">
                        {paginator}
                    </Grid>

                    {text !== "" && (
                        <Row className="mt-3 tablet:hidden" lastChild="to-right">
                            <Tab className="bg-adjust-tone-17 -my-2 w-auto rounded-full">
                                <TabItem className="rounded-full" active={sort} onClick={() => setSort(sort ? false : true)}>
                                    Ordina per data
                                </TabItem>
                                <TabItem className="rounded-full" active={!sort} onClick={() => setSort(sort ? false : true)}>
                                    Ordina per pertinenza
                                </TabItem>
                            </Tab>
                        </Row>
                    )}

                    <Grid columns="2" className="my-4">
                        {results.map((r, i) => (
                            <div key={i} onClick={() => onClickArticle(r)}>
                                <ArticleCard handleLoader={handleLoader} data={r} prodID={idProdotto} user={user} />
                            </div>
                        ))}
                    </Grid>

                    <Row lastChild="to-right" className="mobile:hidden">
                        {paginator}
                        <Button variant="primary-outline" icon="navigation-hide" onClick={scrollTop}>
                            Torna su
                        </Button>
                    </Row>

                    <Grid className="tablet:hidden" gutter="small">
                        {paginator}
                        <div>
                            <Button variant="primary-outline" icon="navigation-hide" onClick={scrollTop}>
                                Torna su
                            </Button>
                        </div>
                    </Grid>
                </div>
            )}
        </div>
    );
};

export default SearchResults;
