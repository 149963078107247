import { Button, Detail, Form, Grid, InputText, Radio, Textarea } from "@maggioli-design-system/react";
import { ChangeEvent, useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { getForms, getMyIP, sendDataToSubscriptions } from "../../services/Privacy";
import { IHandleLoader } from "../../interfaces/IHandleLoader";
import { ISubmit } from "../../interfaces/ISubmit";
import DOMPurify from "dompurify";

interface IContactForm {
    loader: IHandleLoader;
}

interface IFormField {
    elem: IField;
}

interface IFormFields {
    form: IField[];
}

interface IHiddenFields {
    form: IField[];
}

interface IOptions {
    value: string;
    text: string;
}

interface IField {
    input: string;
    name: string;
    label: string;
    type: string;
    placeholder: string;
    options: IOptions;
}

interface IPrivacy {
    description: string;
    fields: IField[];
}

interface IForm {
    privacy: IPrivacy[];
    fields: IField[];
    servizio: string;
    consenso_informato: string;
}

// Canpo input del form
const FormField = ({ elem }: IFormField) => <InputText name={elem.name} label={elem.label} type={elem.type} placeholder={elem.placeholder} />;

// Canpo input del form
const TextAreaField = ({ elem }: IFormField) => <Textarea name={elem.name} label={elem.label} type={elem.type} placeholder={elem.placeholder} />;

// Campo nascosto del form
const HiddenField = ({ elem }: IFormField) => <input name={elem.name} value={elem.options.value} type={elem.type} />;

// Lista dei campi input del form
const FormFields = ({ form }: IFormFields) => (
    <>
        {form
            .filter((elem) => elem.type !== "hidden")
            .map((elem, i) => (
                <FormField elem={elem} />
            ))}
    </>
);

// Lista dei campi nascosti del form
const HiddenFields = ({ form }: IHiddenFields) => (
    <>
        {form
            .filter((elem) => elem.type === "hidden")
            .map((elem, i) => (
                <HiddenField elem={elem} />
            ))}
    </>
);

const TOKEN: string =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJwcml2YWN5Lm1hZ2dpb2xpY2xvdWQuaXQiLCJhdWQiOjEsImlhdCI6MTYzNjQ0OTY0NSwibmJmIjoxNjM2NDQ5NjQ1LCJleHAiOjE5NTE4MDk2NDUsImRhdGEiOnsiaWQiOjc4LCJzaXRlX2lkIjoxMDZ9fQ.S-6zYnMqP5wSZtYLiMh_6HaY-cl5Y9-QF5ckoszV5E4";

const ContactForm = ({ loader }: IContactForm) => {
    const [form, setForm] = useState<IForm>(null);
    const [submit, setSubmit] = useState<ISubmit>({ status: "", text: "Invia" });

    const [commerciale, setCommerciale] = useState<string>(null);

    const [sendingMail, setSendingMail] = useState<boolean>(false);

    const checkErrors: boolean = commerciale !== null;

    useEffect(() => {
        getPrivacy();
        return () => setForm(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPrivacy = async () => {
        loader.setShowLoader(true);
        getForms(TOKEN, (result) => {
            loader.setShowLoader(false);
            setForm(result.form.filter((f) => f.nome === "contatti").pop());
        });
    };

    const privacyHandler = (value: string, privacyName: string) => {
        switch (privacyName) {
            case "commerciale":
                setCommerciale(value);
                break;
            default:
                break;
        }
    };

    // Campi consensi privacy
    const privacyFields = () => {
        return form.privacy.map((elem: IPrivacy, index: number) => (
            <div key={index}>
                <Detail className="privacy-consent" htmlTag={"div"}>
                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(elem.description) }} />
                    {elem.fields.map((item: IField, i: number) => (
                        <Radio
                            key={i}
                            name={item.name}
                            value={item.options.value}
                            onChange={(e: ChangeEvent<HTMLFormElement>) => privacyHandler(e.target.value, item.name)}
                        >
                            {item.options.text}
                        </Radio>
                    ))}
                </Detail>
            </div>
        ));
    };

    const onClickSendRequest = async (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (submit.status !== "") {
            return;
        }
        const resIP = await getMyIP();
        if (!resIP.ok) {
            setSubmit({ text: "Errore nel recupero dell'IP", status: "error" });
            return;
        }
        const dataIP = await resIP.json();
        const formData = new FormData(e.target);
        const privacy = { token: TOKEN, ip: dataIP.ip };
        for (const [key, value] of formData.entries()) {
            privacy[key] = value;
        }

        const data = {
            to: "clienti.editore@maggioli.it", // email amministrazione
            from: "no-reply-siti-web@maggiolieditore.it", //email no replay sito
            fromName: `NormePA`, //nome sito
            replyTo: formData.get("email"), //email inserita dall'utente
            subject: "NormePA - Richiesta informazioni",
            message: `<b>Info utente</b> <br/>Nome: ${formData.get("nome")}<br/>Cognome: ${formData.get("cognome")}
                        <br/>Email: ${formData.get("email")}<br/><br/>
                        <b>Messaggio</b><p>${formData.get("messaggio")}</p><br/>`,
            responseParams: {
                to: formData.get("email"), //email inserita dall'utente
                message: "Grazie per averci contattato. A breve riceverai una risposta da parte dell'amministrazione di NormePA.",
            },
            privacy: privacy,
        };

        setSendingMail(true);
        sendDataToSubscriptions(data, (res) => {
            setSendingMail(false);
            if (!res.response_status) {
                setSubmit({ status: "error", text: res.message });
                window.setTimeout(() => setSubmit({ status: "", text: "Invia" }), 2500);
                return;
            }
            setSubmit({
                status: "success",
                text: "Richiesta inviata con successo!",
            });
            window.setTimeout(() => (window.location.href = "/"), 1000);
        });
    };

    return (
        <Grid gutter="xsmall mb-10">
            {form !== null && (
                <Form onSubmit={(e: ChangeEvent<HTMLFormElement>) => onClickSendRequest(e)}>
                    {/* Campi form presi da Privacy */}
                    <Grid columns="2" gutter="small">
                        <FormFields form={form.fields.filter((f) => f.input === "input")} />
                    </Grid>

                    {/* Testo del messaggio */}
                    {form.fields
                        .filter((f) => f.input === "textarea")
                        .map((f) => (
                            <TextAreaField elem={f} />
                        ))}

                    {/* Campi nascosti da Privacy */}
                    <HiddenFields form={form.fields} />

                    {/* Servizio */}
                    <input name="servizio" value={form.servizio} type="hidden" />

                    {/* Consenso informato trattamento */}
                    <input name="trattamento" value="1" type="hidden" />

                    {/* Consenso informato */}
                    <Detail htmlTag={"div"}>
                        <p className="privacy-consent" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(form.consenso_informato) }} />
                    </Detail>

                    {/* Campi privacy */}
                    {privacyFields()}

                    {/* Bottone invia */}
                    {!sendingMail ? (
                        <Button icon="file-email" type="submit" disabled={!checkErrors} variant={submit.status}>
                            {submit.text}
                        </Button>
                    ) : (
                        <div className="view-limit">
                            <ThreeDots color="#0041B9" />
                        </div>
                    )}
                </Form>
            )}
        </Grid>
    );
};

export default ContactForm;
