import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Singleton from "./utils/Singleton";
import { APPRO_URL, getCookie } from "./utils/Constants";
const awaitToken = async () => {
    try {
        const response = await fetch(process.env.REACT_APP_REDA_URL + "api/authenticate", {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            body: JSON.stringify({
                username: process.env.REACT_APP_REDA_USER,
                password: process.env.REACT_APP_REDA_PASS,
            }),
        });

        const content = await response.json();

        return content.id_token;
    } catch (e) {
        console.log(e);
        return false;
    }
};

const PreRender = () => {
    const [token, setToken] = React.useState(false);
    const [isWorker, setIsWorker] = React.useState(false);

    useEffect(() => {
        checkUserAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkUserAccess = async () => {
        const loggedUser = JSON.parse(getCookie("loggedUser"));
        const isAppro = loggedUser !== null && loggedUser.type === "approfondimenti";
        if (loggedUser === null || !isAppro) {
            return;
        }
        const res = await fetch(`${APPRO_URL}core/auth/api/v2/get/users/canAccess/${loggedUser.id}/${loggedUser.email}`, {
            method: "GET",
            headers: {
                "Authorization": "Basic " + btoa(`${process.env.REACT_APP_APPRO_USER}:${process.env.REACT_APP_APPRO_PASS}`),
                "Content-Type": "application/json",
            },
        });

        if (!res.ok) {
            return false;
        }
        const data = await res.json();
        setIsWorker(data.status);
    };

    const resolve = async () => {
        const token = await awaitToken();
        if (!token) {
            setToken(false);
        } else {
            Singleton.getInstance(token);
            setToken(token);
        }
    };

    const user = {
        isWorker,
    };

    React.useEffect(() => {
        resolve();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return token ? <App user={user} /> : null;
};

ReactDOM.render(
    <React.StrictMode>
        <PreRender />
    </React.StrictMode>,
    document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
