import React, { useState } from "react";
import { Button, H1, H4, Detail } from "@maggioli-design-system/react";

import HeroImage from "../../assets/images/norme-pa.svg";
import "./Hero.css";
import clsx from "clsx";

const Hero = () => {
    const [visible, showInfos] = useState<boolean>(false);

    return (
        <div className="bg-status-info-09 text-adjust-tone py-10">
            <div className="desktop:grid-cols-2 grid view-limit overflow-hidden">
                <div className={clsx(visible && "desktop:opacity-0", "transition-opacity duration-300 items-center")}>
                    <div className="mt-12 desktop:max-w-sm gap-4 grid ml-auto tablet-max:p-4 tablet-max:text-center">
                        <H1>
                            Benvenuto in <span className="whitespace-nowrap">Norme PA</span>
                        </H1>
                        <H4>I principali testi normativi annotati con la giurisprudenza e la prassi più significativa</H4>
                        <div className="tablet-max:hidden">
                            <Button size="xlarge" width="inline" onClick={() => showInfos(true)}>
                                Scopri il servizio
                            </Button>
                        </div>
                    </div>
                </div>
                <div
                    className={clsx(
                        visible && "desktop:-translate-x-full",
                        "desktop:flex duration-500 tablet-max:p-4 transition-transform translate",
                    )}
                >
                    <div>
                        <img className="mt-8 max-w-sm w-full h-auto m-auto" alt="Norme PA" src={HeroImage} />
                    </div>
                    {visible && (
                        <div
                            className={clsx(
                                "desktop:opacity-100 w-4/3",
                                "desktop:absolute desktop:opacity-0 desktop:translate-x-2/4 gap-4 grid p-4 tablet:grid-cols-2 transition-cosmetic tablet-max:w-full",
                            )}
                        >
                            <div className="grid gap-4 auto-rows-min">
                                <Detail>
                                    Norme PA è il servizio che mette a disposizione i principali provvedimenti normativi di interesse per la Pubblica
                                    Amministrazione in versione consolidata, ovvero costantemente aggiornati con le modifiche ed integrazioni
                                    apportate nel tempo dal legislatore.
                                </Detail>
                                <Detail>
                                    Il servizio è completato da una rubrica che segnala le ultime circolari, i pareri e le sentenze di particolare
                                    rilevanza.
                                </Detail>
                            </div>
                            <div className="grid gap-4 auto-rows-min">
                                <Detail>
                                    In calce a ciascun articolo del provvedimento sono riportati gli orientamenti più significativi della prassi
                                    amministrativa e della giurisprudenza, per aiutare il lettore ad interpretare la norma al fine di applicarla
                                    correttamente nella pratica professionale quotidiana.
                                </Detail>
                                <Detail>
                                    Un motore di ricerca agevola la rapida individuazione dei documenti (per estremi o per parole chiave).
                                </Detail>
                                <Button className="tablet-max:hidden" icon="action-back" onClick={() => showInfos(false)}>
                                    Indietro
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Hero;
