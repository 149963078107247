import React from "react";
import clsx from "clsx";

//Design system
import { InputText } from "@maggioli-design-system/react";

interface Props {
    className?: string,
    onChange?: Function,
    placeholder?: string,
    value?: string,
}

const Search = ({ className, onChange, value = '', placeholder = 'Cerca...' }: Props) => {
    return <InputText className={clsx(className, 'grid gap-2 overflow-visible')} icon="data-search" placeholder={placeholder} 
                    fill={false} grow onChange={e => onChange(e)} value={value}/>
    
}

export default Search;
