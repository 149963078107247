export default class Singleton {
    static token = null;

    static getInstance(token: null | string = null) {
        if (Singleton.token === null) {
            Singleton.token = token !== null ? token : Singleton.token;
        }
        return this.token;
    }
}

// export class SingletonProducts {
//     static data = null;

//     static getInstance(data: IProd[] | null = null) {
//         if (SingletonProducts.data === null) {
//             SingletonProducts.data = data !== null ? data : SingletonProducts.data;
//         }
//         return this.data;
//     }
// }

interface IProd {
    id_prodotto: string;
    scadenza: string;
}
