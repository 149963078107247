import TextCac from "../components/Texts/TextCac";
import TextuTupi from "../components/Texts/TextTupi";
import { IProdotto } from "../interfaces/IProduct";
import tupi_image from "../assets/images/Tupi.png";
import ccp_image from "../assets/images/Ccp.png";
import tuel_image from "../assets/images/tuel.png";
import ldue_image from "../assets/images/l241.png";
import cdsc_image from "../assets/images/cdsc.png";
import tusp_image from "../assets/images/tusp.jpg";
import dlgs_image from "../assets/images/dlgs201.png";
import TextL214 from "../components/Texts/TextL214";
import TextTuel from "../components/Texts/TextTuel";
import TextCdsc from "../components/Texts/TextCdsc";
import TextTusp from "../components/Texts/TextTusp";
import TextDlgs from "../components/Texts/TextDlgs";
import { Keycloak } from "keycloak-auth";
import { authorization } from "./Functions";
import { IActiveSub } from "../pages/Home/Home";

export const APPRO_URL: string = "https://approfondimenti.maggiolicloud.it/";
export const APPRO_REGISTER_URL: string = `${APPRO_URL}users/register`;

export const clientID: string = process.env.REACT_APP_CLIENT_ID;
export const clientSecret: string = process.env.REACT_APP_CLIENT_SECRET;
// payload di keycloak
export const keycloakPayload = {
    "realm": "maggioli",
    "auth-server-url": "https://sso.maggioli.com/",
    "ssl-required": "external",
    "resource": clientID,
    "credentials": {
        "secret": clientSecret,
    },
    "confidential-port": 0,
};

// crea un cookie
export const setCookie = (name: string, value: string, seconds: number) => {
    const date = new Date();
    date.setTime(date.getTime() + seconds * 1000);
    const expires = "; expires=" + date.toUTCString();
    document.cookie = name + "=" + (value || "") + expires + "; Secure; SameSite=Lax; path=/";
};

// se c'è, recupera un cookie. altrimenti ritorna null
export const getCookie = (name: string) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
};

export const emailParams = {
    host: "smtp.sendgrid.net",
    port: 465,
    user: process.env.REACT_APP_SEND_USER,
    password: process.env.REACT_APP_SEND_PASS,
};

export const reda: any = {
    treeURL: process.env.REACT_APP_REDA_URL + "api/sites/voce-albero/",
    docURL: process.env.REACT_APP_REDA_URL + "api/sites/document/",
    rimandiURL: process.env.REACT_APP_REDA_URL + "api/sites/document/",
    prodottoURL: process.env.REACT_APP_REDA_URL + "api/sites/prodotti/",
    searchDocURL: process.env.REACT_APP_REDA_URL + "api/sites/document/_search?",
    redaDocURL: process.env.REACT_APP_REDA_URL + "api/sites/document/_search?categorie=",
};

export const cacID: string = "4722636";
const tupiRedaID: string = "5648011";
const tuelRedaID: string = "5648014";
const l241ID: string = "5648015";
const cdscID: string = "5648012";
const tuspID: string = "20008301";
const dlgsID: string = "20009101";

const serviziID: string = "88082";
const anuscaID: string = "88083";
const appaltiID: string = "2173095";
const tributiID: string = "1041127";
const poliziaID: string = "3479336";
const bilancioID: string = "2185215";
const gazzettaID: string = "2968874";
const ediliziaID: string = "1556974";
const personaleID: string = "3608684";
const commercioID: string = "1925237";
const publicID: string = "4472180";

export const products: IProdotto[] = [
    {
        id: tupiRedaID, // ID prodotto redazionale
        title: "Testo Unico del Pubblico Impiego",
        img: tupi_image,
        isbn: ["9788891655530", "9788891664198"],
        validProductIDs: [tupiRedaID, personaleID], // ID prodotti abbonamenti
        description: <TextuTupi />,
    },
    {
        id: cacID,
        title: "Codice dei Contratti Pubblici Commentato",
        img: ccp_image,
        isbn: ["9788891651723"],
        validProductIDs: [cacID],
        description: <TextCac />,
    },
    {
        id: tuelRedaID,
        title: "Testo Unico degli Enti Locali Commentato",
        img: tuel_image,
        isbn: ["9788891658005", "9788891664228"],
        validProductIDs: [ gazzettaID, commercioID, poliziaID, tributiID, bilancioID ],
        description: <TextTuel />,
    },
    {
        id: l241ID,
        title: "La legge 241/90 commentata con la giurisprudenza",
        img: ldue_image,
        isbn: ["9788891658616", "9788891665607"],
        validProductIDs: [appaltiID, gazzettaID, personaleID, ediliziaID, tributiID, bilancioID, serviziID, poliziaID, commercioID, anuscaID],
        description: <TextL214 />,
    },
    {
        id: cdscID,
        title: "Codice della stada commentato",
        img: cdsc_image,
        isbn: ["9788891659750"],
        validProductIDs: [poliziaID],
        description: <TextCdsc />,
    },
    {
        id: tuspID,
        title: "Testo Unico Società a Partecipazione Pubblica Commentato",
        img: tusp_image,
        isbn: [],
        validProductIDs: [publicID],
        description: <TextTusp />,
    },
    {
        id: dlgsID,
        title: "Dlgs 201/2022 commentato con prassi e giurisprudenza",
        img: dlgs_image,
        isbn: [],
        validProductIDs: [publicID],
        description: <TextDlgs />,
    },
];

export const productsID: string[] = [
    dlgsID,
    tuspID,
    tupiRedaID,
    cacID,
    personaleID,
    tuelRedaID,
    l241ID,
    gazzettaID,
    appaltiID,
    ediliziaID,
    tributiID,
    bilancioID,
    serviziID,
    poliziaID,
    commercioID,
    anuscaID,
];

export const getProduct = (data: string) => {
    let prod = products.filter((p) => p.id === data).pop();
    return prod ? prod : null;
};

export const productExists = (data: string) => {
    return getProduct(data) !== null;
};

export const flatProducts = (data: IActiveSub[]): string[] =>  {
    const prodottiAttivi: string[] = data.map((p: IActiveSub) => p.id_prodotto);
    data.forEach((p: IActiveSub) => {
        p.sub_products.forEach((s: IActiveSub) => {
            !prodottiAttivi.includes(s.id_prodotto) && prodottiAttivi.push(s.id_prodotto);
        });
    });
    return prodottiAttivi;
}

export const checkAccess = async (prodID: string) => {
    const loggedUser = JSON.parse(getCookie("loggedUser"));
    if (loggedUser === null) {
        return false;
    }
    const isAppro: boolean = loggedUser.type === "approfondimenti";
    if (prodID === undefined || prodID === null) {
        return false;
    }
    if (isAppro) {
        return false;
    } else {

        const refreshToken = getCookie("refresh_token");

        const dataKey = await Keycloak.auth().getToken(refreshToken, true);
        if (!dataKey.access_token) {
            return false;
        }

        setCookie("access_token", dataKey.access_token, dataKey.expires_in);
        setCookie("refresh_token", dataKey.refresh_token, dataKey.refresh_expires_in);

        const products = await authorization(dataKey.access_token);
        if(!products) {
            return false;
        }
        const prodottiAttivi: string[] = flatProducts(products);

        return (
            prodID === cacID ||
            (productExists(prodID) && prodottiAttivi.filter((r) => getProduct(prodID).validProductIDs.includes(r))).length > 0
        );
    }
};

export const docTypes: string[] = [ "giurisprudenza", "prassi", "normativa" ];

export const capFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getMaxPage = (size: number, tot: number) => {
    if (tot <= size) {
        return 1;
    }
    const data: number = Math.floor(tot / size);
    return tot % size > 0 ? data + 1 : data;
};

export const getDescription = (productID: string) => {
    return productExists(productID) ? products.filter((p) => p.id === productID).pop().description : null;
};

export const NORME_PA_URL: string = process.env.REACT_APP_BASE_URL;
export const SUB_URL: string = "https://subscriptions.maggiolicloud.it/";

export const oneDayInSeconds: number = 24 * 60 * 60;
