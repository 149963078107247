import React, { useEffect, useState } from "react";

// Design-syster import
import { Card, Detail, Grid, Row, Button, Tag, Caption, Icon } from "@maggioli-design-system/react";
import { IHandleLoader } from "../../interfaces/IHandleLoader";
import { downloadDocument, getNewsLink } from "../../services/Redazionale";
import { IDocument } from "../../interfaces/IDocument";
import { checkAccess, getCookie, getProduct, productExists } from "../../utils/Constants";
import { textHighlight } from "../../utils/Helper";
import { checkUserAuthorization } from "../../services/Approfondimenti";
import { IUserRole } from "../../App";
import DOMPurify from "dompurify";

interface Props {
    data: IDocument;
    handleLoader: IHandleLoader;
    prodID?: string;
    user: IUserRole;
}

const ArticleCard = ({ data, handleLoader, prodID, user }: Props) => {
    const TITLE_LENGTH: number = 200;
    const DESC_LENGTH: number = 300;
    const loggedUser = JSON.parse(getCookie("loggedUser"));

    const [links, setLinks] = useState<IDocument[]>([]);
    const [approAuth, setApproAuth] = useState<boolean>(false);
    const [subAuth, setSubAuth] = useState<boolean>(false);

    useEffect(() => {
        prodID !== undefined && loadLinks();
        checkAuthorization(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const checkApproAuthorization = async () => {
    //     if (!isAppro || !productExists(prodID)) {
    //         return;
    //     }
    //     const res = await checkUserAuthorization(loggedUser.id, getProduct(prodID).isbn);
    //     setApproAuth(res.status);
    // };

    const checkAuthorization = async () => {
        if (!productExists(prodID)) {
            return;
        }
        if (loggedUser === null) {
            return;
        }
        const isAppro: boolean = loggedUser.type === "approfondimenti";
        if(isAppro) {
            const res = await checkUserAuthorization(loggedUser.id, getProduct(prodID).isbn);
            setApproAuth(res.status || user.isWorker);
        } else {
            const res2 = await checkAccess(prodID);
            setSubAuth(res2);
        }
    };

    const loadLinks = async () => {
        const res = await getNewsLink(data.id.toString(), prodID);
        res && setLinks(res);
    };

    const onClickDownload = async (e) => {
        e.stopPropagation();
        handleLoader.setShowLoader(true);
        const res = await downloadDocument(data.id);
        handleLoader.setShowLoader(false);
        if (!res) {
            return;
        }
        const element = window.document.createElement("a");
        element.href = URL.createObjectURL(res);
        element.download = `${data.descrizione}.${data.ftype}`;
        window.document.body.appendChild(element);
        element.click();
    };

    const title: string =
        data.descrizione && data.descrizione.length > TITLE_LENGTH
            ? textHighlight(data.descrizione, data).slice(0, TITLE_LENGTH) + "..."
            : textHighlight(data.descrizione, data);
    const obj: string =
        data.titolo && data.titolo.length > DESC_LENGTH
            ? textHighlight(data.titolo, data).slice(0, DESC_LENGTH) + "..."
            : textHighlight(data.titolo, data);
    const cursor: string = data.ftype === "pdf" ? "" : "cursor-pointer";

    const canAccess: boolean = approAuth || subAuth;

    return (
        <Card rows="fit-vertically" className={`shadow hover:shadow-lg gap-2 mobile:gap-0 ${cursor} min-h-44`}>
            <Grid gutter="xsmall">
                <div className="flex gap-2 items-start">
                    <Detail className="w-56 flex-grow">
                        <b dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }} />
                    </Detail>
                    <div className="mobile:hidden flex-shrink-0 flex items-center gap-2">
                        <Tag size="xxsmall" className="px-2" chip>
                            {data.type.charAt(0).toUpperCase() + data.type.slice(1)}
                        </Tag>
                        {data.ftype === "pdf" && canAccess && (
                            <Button icon="action-download" onClick={(e) => onClickDownload(e)} className="px-2">
                                PDF
                            </Button>
                        )}

                        {data.ftype === "pdf" && !canAccess && <Icon size="small" name="document" />}
                    </div>
                </div>

                <div className="tablet:hidden">
                    <Row>
                        <Tag chip>{data.type.charAt(0).toUpperCase() + data.type.slice(1)}</Tag>
                        {data.ftype === "pdf" && canAccess && (
                            <Button icon="action-download" onClick={(e) => onClickDownload(e)} className="px-2">
                                PDF
                            </Button>
                        )}

                        {data.ftype === "pdf" && !canAccess && <Icon size="small" name="document" />}
                    </Row>
                </div>
                <div>
                    <Detail className="text-adjust-tone-08">
                        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(obj) }} />
                    </Detail>
                </div>
                {links.length > 0 && (
                    <Grid gutter="none" className="mt-2">
                        <Caption className="text-adjust-tone-04">
                            (
                            {links
                                .slice(0, 8)
                                .map((l) => l.descrReda)
                                .join(", ")}
                            )
                        </Caption>
                    </Grid>
                )}
            </Grid>
        </Card>
    );
};

export default ArticleCard;
