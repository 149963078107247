import React, { ReactElement, useState } from "react";
import { Button, H3, H4, Grid, Card, Detail, Paragraph, Row, Icon, UList, UListItem } from "@maggioli-design-system/react";
import { Link } from "react-router-dom";

export default function Help(): ReactElement {
    const [context, setContext] = useState<number>(1);

    const stepCard = (icon: string, title: string, desc: string, nContext: number) => {
        const selected: string = nContext === context ? "bg-adjust-tone-16" : "";
        return (
            <div onClick={() => setContext(nContext)} className="cursor-pointer">
                <Card className={`color-brand-maggioli-05 hover:bg-adjust-tone-17 ${selected}`} padding="xsmall">
                    <Row>
                        <Icon size="xlarge" name={icon} />

                        <Grid gutter="none" columns="1">
                            <Paragraph className="color-adjust-tone-04">{title}</Paragraph>
                            <Detail className="color-adjust-tone-04">{desc}</Detail>
                        </Grid>
                    </Row>
                </Card>
            </div>
        );
    };

    const registerContent = () => (
        <Card rows="fit-vertically" className="desktop:col-span-2 background-color-adjust-tone-19">
            <H4>Registrazione</H4>
            <Detail>
                Clicca il pulsante “Registrati” per creare il tuo account. Riceverai un’email di conferma nella quale troverai un link per
                l&#39;attivazione dell&#39;account su "NormePA". In caso di mancato ricevimento dell'email controllare la casella di posta
                indesiderata.
            </Detail>
            <Link to="/register">
                <Button variant="primary-outline">Registrati</Button>
            </Link>
        </Card>
    );

    const loginContent = () => (
        <Card rows="fit-vertically" className="desktop:col-span-2 background-color-adjust-tone-19">
            <H4>Come accedere</H4>
            <Detail>
                Per accedere alla versione digitale dei testi normativi disponibili su NormePA è necessario:
                <UList>
                    <UListItem>aver acquistato il volume;</UListItem>
                    <UListItem>oppure essere abbonati all’area tematica di SistemaPA o al servizio internet di riferimento.</UListItem>
                </UList>
                <br />
                Se hai acquistato il volume dovrai:
                <UList>
                    <UListItem>compilare il form presente nella sezione REGISTRATI;</UListItem>
                    <UListItem>
                        dopo aver ricevuto l’email di conferma, cliccare sul pulsante in alto a destra per inserire il CODICE D&#39;ACCESSO riportato
                        nella terza di copertina del volume.
                    </UListItem>
                </UList>
                <br />
                Se invece sei abbonato all’area tematica di SistemaPA o al servizio internet di riferimento (appaltiecontratti.it, ilpersonale.it.
                ufficiotributi.it, ecc), clicca su ABBONATO SERVIZI ONLINE.
                <br />
                <br />
            </Detail>
        </Card>
    );

    const redeemCodeContent = () => (
        <Card rows="fit-vertically" className="desktop:col-span-2 background-color-adjust-tone-19">
            <H4>Codice d'accesso</H4>
            <Detail>
                Effettuato l'accesso a "NormePA" sarà possibile utilizzare i codici relativi ai volumi cliccando sul pulsante "Inserisci codice
                d'accesso" che apparirà nella barra in alto dopo il login. <br /> <br />
                <b>Nota:</b> tale operazione non sarà necessaria nel caso in cui l'accesso avvenga tramite le credenziali di abbonamento ai servizi
                online.
            </Detail>
        </Card>
    );

    return (
        <div className="padding-normal background-color-adjust-tone-19 my-10">
            <H3 className="text-center">Guida</H3>

            <Grid className="view-limit desktop:px-8 mt-10 lg:grid-cols-4 desktop-max:px-5" columns="4" gutter="" rows="fit-vertically">
                <Grid className="desktop:col-span-1" rows="fit-vertically">
                    {stepCard("security-fingerprint", "Accesso", "", 1)}
                    {stepCard("crud-create", "Registrati", "", 2)}
                    {stepCard("code-isbn", "Codice d'accesso", "", 3)}
                </Grid>

                {context === 1 && loginContent()}
                {context === 2 && registerContent()}
                {context === 3 && redeemCodeContent()}
            </Grid>
        </div>
    );
}
